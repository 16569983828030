import { useAuthStore } from '~/store/auth.store';
import { IRefreshedTokens } from '~/auth/interfaces/refreshed-tokens';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { RService } from '~/services/api/r/r-service-class';
import { getSafeNavigator } from '~/helpers';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { IStrategy } from '~/auth/interfaces/strategy';
import { AuthService } from '~/auth/auth-service';
import { ITokenCtoRes } from '~/services/api/r/interfaces/get-token-cto';

export abstract class BaseStrategy implements IStrategy {
  abstract authenticate(options: never): Promise<void>;

  abstract getGateKeepers(): IGatekeeper[];

  protected currentAuthorizedRoutes: Routes[] = [];

  async refreshToken(): Promise<IRefreshedTokens> {
    try {
      const oldToken = this.getToken();
      const oldRefreshToken = this.getRefreshToken();
      const { token, refreshToken } = await OlimpoService.login.refreshToken({
        token: oldToken as string,
        refreshToken: oldRefreshToken as string,
      });

      useAuthStore.setState({ token, refreshToken });

      return Promise.resolve({ token, refreshToken });
    } catch (err) {
      return Promise.resolve({ token: null, refreshToken: null });
    }
  }

  async onLogout() {
    useAuthStore.setState({
      token: null,
      refreshToken: null,
      user: null,
      dependents: null,
      selectedProduct: null,
      userProducts: null,
      isFirstAccess: false,
    });
  }

  async onBeforeTokenExpire(): Promise<IRefreshedTokens> {
    return this.refreshToken();
  }

  /**
   * Run when the token has expired and the onBeforeTokenExpire was already triggered to try
   * to refresh the session
   */
  async onTokenExpire(): Promise<void> {
    await AuthService.logout();
  }

  getToken() {
    return useAuthStore.getState().token;
  }

  getRefreshToken() {
    return useAuthStore.getState().refreshToken;
  }

  async selectFirstProduct() {
    const products = useAuthStore.getState().userProducts;
    if (products && products.length > 0) {
      await this.selectProduct(products[0].onixCode);
    } else {
      console.warn('[selectFirstProduct] user has no products to select.');
    }
  }

  async selectProduct(onix: string) {
    const userProducts = useAuthStore.getState().userProducts;

    if (userProducts) {
      const selectedProduct = userProducts.find((p) => p.onixCode === onix);

      if (selectedProduct) {
        const config = await this.fetchProductConfig(onix);

        if (config) {
          useAuthStore.setState({
            selectedProduct: { ...selectedProduct, ...config },
          });
        } else {
          console.warn(`[selectProduct] product ${onix} config not found`);
        }

        return;
      }
    }

    console.warn(`[selectProduct] product ${onix} not found`);
  }

  async updateAllAuthData() {
    const promises = [
      this.updateProfileData(),
      useAuthStore.getState().selectedProduct?.onixCode ? this.updateMyDependents() : undefined,
      (async () => {
        await this.updateMyProducts();
        await this.updateProductConfig();
      })(),
    ];

    await Promise.all(promises);
  }

  async updateIsDependent(): Promise<void> {
    const user = useAuthStore.getState().user;

    if (!user) {
      return console.warn('updateIsDependent: user was not set yet');
    }

    const userCto = await RService.getTokenCto({
      cpf: user.cpf,
      onix: AuthService.getSelectedOnix() as string,
      token: (await AuthService.getToken()) as string,
    });

    useAuthStore.setState((st) => ({
      user: st.user
        ? {
            ...st.user,
            isDependent: userCto.userType === 'DEPENDENTE',
          }
        : null,
    }));
  }

  async updateProfileData() {
    const user = await OlimpoService.profile.getMyProfile();

    let userCto: null | ITokenCtoRes = null;
    if (AuthService.getSelectedOnix()) {
      userCto = await RService.getTokenCto({
        cpf: user.cpf,
        onix: AuthService.getSelectedOnix() as string,
        token: (await AuthService.getToken()) as string,
      });
    }

    useAuthStore.setState((st) => ({
      user: {
        ...st.user,
        ...user,
        ...(userCto ? { isDependent: userCto.userType === 'DEPENDENTE' } : { isDependent: null }),
      },
    }));
  }

  async checkHasPassword() {
    const user = useAuthStore.getState().user;
    if (user?.cpf) {
      await OlimpoService.login.checkHasPassword(user.cpf);
    }
  }

  async updateMyDependents() {
    const dependents = await RService.getMyDependents({
      cpf: useAuthStore.getState().user?.cpf as string,
      tokenzeus: useAuthStore.getState().token as string,
      onix: useAuthStore.getState().selectedProduct?.onixCode as string,
    });
    useAuthStore.setState({ dependents });
  }

  async updateMyProducts() {
    const userProducts = await OlimpoService.profile.getMyProducts();
    useAuthStore.setState({ userProducts });
  }

  async fetchProductConfig(onix?: string | number) {
    if (!onix) {
      return null;
    }

    const slug = useAuthStore
      .getState()
      .userProducts?.find((product) => product.onixCode === String(onix))?.slug;

    if (!slug) {
      return null;
    }

    return await OlimpoService.partners.getPartner(slug);
  }

  async updateProductConfig() {
    const hasProduct = useAuthStore.getState().selectedProduct;
    if (!hasProduct) {
      return console.warn('updateProductConfig: no product selected');
    }

    const currentOnixCode = useAuthStore.getState().selectedProduct?.onixCode;
    const partner = await this.fetchProductConfig(currentOnixCode);

    useAuthStore.setState((st) => ({
      selectedProduct: st.selectedProduct ? { ...st.selectedProduct, ...partner } : null,
    }));
  }

  async onNotFound() {
    const navigator = await getSafeNavigator();
    navigator.navigate(this.getAuthorizedRoutes()[0] as never);
  }

  getAuthorizedRoutes(): Routes[] {
    return this.currentAuthorizedRoutes;
  }

  async onGatekeepersPass() {
    void this.updateIsDependent();
    void this.updateMyDependents();
  }

  async triggerGatekeeperValidation() {
    const invalidGatekeepers = await this.getInvalidGatekeepers();

    if (invalidGatekeepers.length > 0) {
      const firstGatekeeper = invalidGatekeepers[0];
      this.currentAuthorizedRoutes = firstGatekeeper.getGatekeeperRoutes();
    } else {
      this.currentAuthorizedRoutes = this.mountAuthorizedRoutes();
      await this.onGatekeepersPass();
    }

    useAuthStore.setState((st) => ({
      invalidGatekeepers,
      authenticationIndex: st.authenticationIndex + 1,
    }));
  }

  async getInvalidGatekeepers() {
    const gatekeepers = this.getGateKeepers();
    const invalidGatekeepers: IGatekeeper[] = [];

    for (const gatekeeper of gatekeepers) {
      if (!(await gatekeeper.canPass(this.buildGatekeeperContext()))) {
        invalidGatekeepers.push(gatekeeper);
      }
    }

    return invalidGatekeepers;
  }

  protected buildGatekeeperContext() {
    return { strategy: this };
  }

  async onStoreLoaded() {
    await this.updateAuthorizedRoutes();
  }

  async updateAuthorizedRoutes() {
    this.currentAuthorizedRoutes = this.mountAuthorizedRoutes();
  }

  getInitialParams() {
    return {};
  }

  isFullAuthenticated() {
    const { user, token, invalidGatekeepers } = useAuthStore.getState();
    return !!user && !!token && invalidGatekeepers.length === 0;
  }

  /**
   * Use this for custom or dynamic authorized routes
   * This will be called after authentication and on store load
   */
  mountAuthorizedRoutes(): Routes[] {
    return [
      'Dashboard',
      'TemPayPresentation',
      'TemPayDashboard',
      'TemPayRecharge',
      'TemPayRechargeBillet',
      'TemPayRechargeCard',
      'AttendanceWebChat',
      'TemPayStatement',
      'TemPayPassword',
      'TemPayHelp',
      'ScheduleAppointment',
      'Schedule24h',
      'FinancialTeleorientation',
      'FinancialEducation',
      'BenefitsTeleOrientation',
      'BenefitsNutritionalAssistance',
      'PsychologicalAssistance',
      'SurgicalProcedure',
      'SurgicalProcedureForm',
      'SurgicalProcedureSuccess',
      'SurgicalProcedureHospital',
      'ScheduleAppointmentSuccess',
      'ScheduleSuccess',
      'ScheduleCheckup',
      'ScheduleTeleorientation',
      'BenefitsDentistry24Hours',
      'RegulationAndPrivacy',
      'MyAppointments',
      'Dependents',
      'DependentsView',
      'DependentsReason',
      'HelpDependents',
      'Signature',
      'SignatureCancel',
      'Profile',
      'UpdateProfileData',
      'ProfileData',
      'ProfilePassword',
      'CreateDependents',
      'EditDependents',
      'UpdateProfileAddress',
      'ProfileAddress',
      'DependentsPresentation',
      'BenefitsPresentation',
      'BenefitsFunerals',
      'PsychologicalTherapy',
      'PharmacyBenefit',
      'BenefitsNetwork',
      'BenefitsServicesConsultations',
      'MyClinic',
      'MyCreditCards',
      'RegisterCreditCard',
      'BenefitsNetworkSearch',
      'BenefitsDiscountMedicines',
      'BenefitsDiscountMedicinesSearch',
      'BenefitsDigitalWellBeing',
      'BenefitsHospitalAdmision',
      'BenefitsDigitalWellBeingSSO',
      'BenefitsHealthyHabitsProgram',
      'BenefitsMentalHealthProgram',
      'BenefitsMindAndBodyProgram',
      'BenefitsTraining',
      'BenefitsWellBeing',
      'BenefitsWoman',
      'BenefitsInsurance',
      'BenefitsInsuranceDeathDisability',
      'BenefitsCapitalization',
      'BenefitsPetAssistance',
      'BenefitsPetFuneralAssistance',
      'BenefitsPetEmergencyAppointment',
      'BenefitsPetTransport',
      'BenefitsPetFoodShipping',
      'BenefitsPetAccommodation',
      'BenefitsCheckup',
      'BenefitsResidentialAssistant',
      'BenefitsParentalAssistance',
      'BenefitsPetAssistant',
      'BenefitsBusinessAssistant',
      'SubsidizedMedicines',
      'BenefitsWelcomingWomen',
      'BenefitsWomensHealth',
      'BenefitsDigitalFemaleAssistance',
      'BenefitsResidentialAssistancePlumber',
      'BenefitsKeychain',
      'BenefitsResidentialAssistanceElectrician',
      'BenefitsResidentialAssistanceHomeAppliance',
      'BenefitsBusinessAssistancePlumber',
      'BenefitsBusinessAssistanceKeychain',
      'BenefitsBusinessAssistanceElectrician',
      'BenefitsBusinessAssistanceHomeAppliance',
      'BenefitsFinancial',
      'VideoAppointmentsSchedule',
      'BenefitsVideoAppointmentsSchedule',
      'BenefitsFinancialClub',
      'MyBenefits',
      'SeniorAssistanceResidentialInspection',
      'SeniorAssistanceCompanion',
      'SeniorAssistanceTransport',
      'BenefitsSeniorAssistance',
      'SeniorAssistanceCaregiver',
    ];
  }
}
