import React, { useEffect, useMemo } from 'react';

import { View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList/CardList';
import SearchIcon from '~/components/Icons/Search';
import DiscountMedicines from '~/components/Icons/DiscountMedicines';
import SubsidizedMedicines from '~/components/Icons/SubsidizedMedicines';
import { useAuth } from '~/auth/legacy/useAuth';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { isMercadoPagoOnix } from '~/helpers/mercado-pago';
import { DISCOUNT_MEDICINES_FEATURES } from '~/helpers/features/discount-medicines';

export const PharmacyBenefit = (): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const theme = useTheme();
  const { tracking } = useTracking();
  const { checkIsEnabledFeature, onixCode } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          name: 'BenefitsDiscountMedicines',
          icon: <DiscountMedicines />,
          title: 'Descontos em Farmácia',
          description: 'Farmácias credenciadas em todo o Brasil',
          onPress: async () => {
            await tracking
              .addMovement({
                event: trackingEventType.ClickedOnDiscountOnMedicines,
              })
              .catch((err) => {
                console.error('Error on tracking clicked on discount on medicines', err);
              });

            navigation.navigate('BenefitsDiscountMedicines');
          },
          visible: checkIsEnabledFeature(['medicine_discounts', ...DISCOUNT_MEDICINES_FEATURES]),
          iconSideOfTitle: true,
        },
        {
          name: 'BenefitsDiscountMedicinesSearch',
          icon: <SearchIcon color={theme.colors.primary} />,
          title: 'Buscar Farmácias',
          description: 'Encontre farmácias perto de você',
          onPress: async () => {
            await tracking
              .addMovement({
                event: trackingEventType.ClickedOnDiscountOnMedicines,
              })
              .catch((err) => {
                console.error('Error on tracking clicked on discount on medicines', err);
              })
              .finally(() => navigation.navigate('BenefitsDiscountMedicinesSearch'));
          },
          visible: checkIsEnabledFeature(['medicine_discounts', ...DISCOUNT_MEDICINES_FEATURES]),
          iconSideOfTitle: true,
        },
        {
          name: 'subsidy_medicines',
          icon: <SubsidizedMedicines />,
          title: 'Subsídio em Medicamentos',
          description: 'Apoio financeiro na compra de medicamentos',
          onPress: () => navigation.navigate('SubsidizedMedicines'),
          visible: checkIsEnabledFeature('subsidy_medicines'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  useEffect(() => {
    if (cards && cards.length == 1) {
      if (isMercadoPagoOnix(onixCode?.onixCode)) {
        const currentCardVisible = cards[0];
        if (currentCardVisible.name == 'BenefitsDiscountMedicines') {
          tracking
            .addMovement({
              event: trackingEventType.ClickedOnDiscountOnMedicines,
            })
            .catch((err) => {
              console.error('Error on tracking clicked on discount on medicines', err);
            });
        }
        navigation.navigate(currentCardVisible.name);
      }
    }
  }, []);

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Benefício Farmácia',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <View className="pb-40">
        <View>
          <CardList data={cards} />
        </View>
      </View>
    </PageWithCard>
  );
};
