import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../../assets/images/seniorAssistenceCompanion/desktop.png';
import mobileBackground from '../../../assets/images/seniorAssistenceCompanion/mobile.png';
import { SeniorAssistanceContactMethods } from '../components/ContactMethods';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import CompanionIcon from '~/components/Icons/Companion';
import { goBack } from '~/helpers';

export const SeniorAssistanceCompanion = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        backgroundImage,
        titleStartContent: <CompanionIcon color={theme.colors.white} />,
        title: 'Acompanhante',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Conte com apoio e companhia em compromissos importantes para uma rotina mais tranquila e
          segura.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Ideal para quem precisa de companhia durante agendamentos, como consultas médicas,
            exames ou compromissos diversos.
          </Typography>
          <View className="mb-8 mt-8">
            <Typography variant="body2">
              O acompanhante ajuda desde a saída de casa até o retorno, garantindo{' '}
              <Typography variant="bodyHighlight2">segurança, atenção</Typography> e{' '}
              <Typography variant="bodyHighlight2">tranquilidade</Typography> em todo o percurso.
              Ideal para quem busca mais conforto e suporte durante essas ocasiões.
            </Typography>
          </View>
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
            acionamentos ao ano.
          </Typography>

          <View className=" mt-8">
            <Typography variant="body2">
              Para mais detalhes consulte o regulamento do seu benefício.
            </Typography>
          </View>

          <View className=" mt-8">
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('RegulationAndPrivacy');
              }}>
              <Typography variant="bodyHighlight2" color={theme.colors.primary}>
                {'Regulamento >'}
              </Typography>
            </TouchableOpacity>
          </View>
        </Collapse>
        <Collapse title="Como utilizar">
          <Typography variant="body2">
            Basta agendar o serviço informando o dia, horário e local do compromisso. Um
            acompanhante estará à disposição no horário combinado para garantir que você chegue ao
            seu destino com toda a segurança, realize seu agendamento e retorne ao seu lar com a
            mesma atenção e cuidado.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <SeniorAssistanceContactMethods />
    </PageWithCard>
  );
};
