import { useFocusEffect } from '@react-navigation/native';
import { clsx } from 'clsx';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Dimensions, FlatList, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { AppointmentProps, EListType } from '../types';
import { useAuth } from '~/auth/legacy/useAuth';
import { Loader, Select, Typography } from '~/components/@hello-ui';
import { PageWithCardContext } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { useApi } from '~/hooks/api';
import { useStatusBarHeight } from '~/hooks/useStatusBarHeight';
import {
  AppointmentItem,
} from '~/screens/MyAppointments/components/AppointmentList/AppointmentItem';
import { Tab } from '~/screens/MyAppointments/components/Tab/Tab';
import { Appointment } from '~/screens/MyAppointments/state/types';
import { isMercadoPagoOnix } from '~/helpers/mercado-pago';

export const AppointmentList = (): JSX.Element => {
  const api = useApi();
  const { headerHeight } = useContext(PageWithCardContext);
  const statusBarHeight = useStatusBarHeight();
  const { user, onixCode } = useAuth();
  const theme = useTheme();

  const getUserName = (user: any) => {
    if (!user) return '';
    if (user?.titularCpf || user?.isDependent) return user.name;
    return `${user.name} (Titular)`;
  };

  const [selectedAppointmentUser, setSelectedAppointmentUser] = useState(
    user?.cpf ? { label: getUserName(user), value: user.cpf } : undefined,
  );
  const { dependents, loadingDependets } = useAuth();

  const [tab, setTab] = useState<EListType>(EListType.Next);
  const [loaded, setLoaded] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const isEmpty = appointments.length === 0;

  const isUnderageAndActive = (user: any) => {
    const today = new Date();
    const birthDate = new Date(user.birthdate ?? '');
    const age = today.getFullYear() - birthDate.getFullYear();
    return age < 18 && user.status == 1;
  };

  const getAppointmentUsers = useCallback(() => {
    if (user?.isDependent) return [{ label: getUserName(user), value: user.cpf }];

    const usersData = [...(dependents?.filter((d) => isUnderageAndActive(d)) || []), user]?.map(
      (user) => ({
        label: getUserName(user),
        value: user?.cpf || '',
      }),
    );

    return usersData;
  }, [dependents, loadingDependets, user]);

  useEffect(() => {
    if (!selectedAppointmentUser?.value) {
      return;
    }
    loadAppointments({ finished: tab === EListType.History });
  }, [tab, selectedAppointmentUser?.value]);

  useFocusEffect(
    useCallback(() => {
      setSelectedAppointmentUser(
        user?.cpf ? { label: getUserName(user), value: user.cpf } : undefined,
      );

      return () => {
        setSelectedAppointmentUser({ label: undefined, value: '' });
      }
    }, []),
  );

  const loadAppointments = async ({ finished }: AppointmentProps) => {
    console.log('🚀 ~ loadAppointments');
    try {
      setLoaded(false);
      setAppointments([]);
      const { data } = await api.getMyAppointments({
        cpf: selectedAppointmentUser ? selectedAppointmentUser.value : user?.cpf ?? '',
        page: 1,
        perPage: 50,
        finished: finished,
      });

      const withoutCheckupOnMP =
        data
          ?.filter((item: any) => {
            if (isMercadoPagoOnix(onixCode?.onixCode) && item.type === 'checkup') {
              return false;
            }
            return true;
          })
          .sort((a: any, b: any) => a.specialty?.name?.localeCompare(b.specialty?.name)) ?? [];

      setAppointments(withoutCheckupOnMP);
    } finally {
      setLoaded(true);
    }
  };

  const changeTab = (type: EListType) => {
    if (!loaded) {
      return
    }
    setTab(type);
  };

  const EmptyComponent = () => {
    return (
      <Typography variant="body2" style={{ textAlign: 'center' }}>
        <Typography variant="bodyHighlight2">Você não possui agendamentos</Typography>
        {'\n'}
        Aproveite para começar a cuidar da saúde!
      </Typography>
    );
  };

  const renderItem = ({ item, index }: { item: Appointment; index: number }) => {
    return (
      <View
        key={index}
        className={clsx('w-full max-w-[530px]', index !== 0 && 'mt-16 mobile:mt-8')}>
        <AppointmentItem {...item} cpf={user?.cpf ?? ''} />
      </View>
    );
  };

  const LoadingIndicator = () => (
    <View className="flex-1 px-16 tablet:px-64 desktop:px-92">
      <View className="w-full max-w-[530px] flex-1">
        <Loader variant="circle-loader" />
      </View>
    </View>
  );

  return (
    <View
      className="pt-24 tablet:pt-40 desktop:pt-40"
      style={{
        height:
          Dimensions.get('window').height -
          headerHeight -
          statusBarHeight -
          (theme.isMobile ? 0 : 90) -
          (Platform.OS === 'ios' ? 40 : 0),
      }}>
      <>
        <View className="mb-20 px-16 mobile:mb-12 tablet:px-64 desktop:px-92">
          <Select
            label="Agendamentos para"
            disabled={!loaded}
            value={selectedAppointmentUser}
            items={getAppointmentUsers()}
            search={false}
            placeholder={'Selecione o usuário'}
            onSelect={(v: any) => {
              setSelectedAppointmentUser(v);
            }}
          />
        </View>
        <View className="mb-20 px-16 mobile:mb-12 tablet:px-64 desktop:px-92">
          <View className="w-full flex-row">
            <Tab active={tab === EListType.Next} onPress={() => changeTab(EListType.Next)}>
              Próximos
            </Tab>
            <Tab active={tab === EListType.History} onPress={() => changeTab(EListType.History)}>
              Histórico
            </Tab>
          </View>
        </View>
        {!loaded ? (
          <LoadingIndicator />
        ) : (
          <FlatList
            data={appointments}
            key={tab}
            renderItem={renderItem}
            ListEmptyComponent={EmptyComponent}
            keyExtractor={(item, idx) => idx.toString()}
            contentContainerStyle={{
              paddingBottom: theme.isMobile ? 16 : theme.isDesktop ? 40 : 24,
              paddingTop: theme.isMobile ? 12 : isEmpty ? 4 : 20,
              paddingHorizontal: theme.isMobile ? 16 : theme.isDesktop ? 92 : 64,
            }}
            showsVerticalScrollIndicator={false}
          />
        )}
      </>
    </View>
  );
};