import { SettingsByFeature } from '~/auth/strategies/deep-link/interfaces/settings-by-feature';

export const SETTINGS_BY_FEATURE: SettingsByFeature = {
  'consulta-presencial': {
    params: {
      toStepper: 0,
    },
    allowedRoutes: ['ScheduleAppointment', 'ScheduleSuccess'],
  },
  exames: {
    params: {
      toStepper: 1,
    },
    allowedRoutes: ['ScheduleAppointment', 'ScheduleSuccess'],
  },
  'consulta-video': {
    params: {
      toStepper: 2,
    },
    allowedRoutes: ['ScheduleAppointment', 'ScheduleSuccess'],
  },
  'consulta-24-horas': {
    params: {
      toStepper: 0,
    },
    allowedRoutes: ['Schedule24h'],
  },
  checkup: {
    params: {
      toStepper: 5,
    },
    allowedRoutes: ['ScheduleAppointment', 'ScheduleSuccess'],
  },
  'gerenciar-dependentes': {
    params: {},
    allowedRoutes: [
      'Dependents',
      'CreateDependents',
      'DependentsView',
      'EditDependents',
      'DependentsReason',
    ],
  },
  'gerenciar-conta-saude': {
    params: {},
    allowedRoutes: [
      'TemPayDashboard',
      'TemPayRecharge',
      'TemPayStatement',
      'TemPayPassword',
      'TemPayHelp',
      'RegisterCreditCard',
    ],
  },
  'desconto-medicamento': {
    params: {},
    allowedRoutes: ['BenefitsDiscountMedicines'],
  },
  'alterar-senha-saude': {
    params: {},
    allowedRoutes: ['TemPayPassword'],
  },
  'editar-meu-perfil-dados': {
    params: {},
    allowedRoutes: ['ProfileData', 'UpdateProfileData'],
  },
};
