import 'react-native-gesture-handler';
import React from 'react';
import { Platform, StatusBar, StatusBarStyle } from 'react-native';
import { initialize } from 'react-native-clarity';

import { RecoilRoot } from 'recoil';
import { API_URL, CLARITY_MOBILE_HASH, DISABLE_CLARITY } from '@env';
import { AuthProvider } from './contexts/auth';
import { TemPayProvider } from './contexts/temPay';
import { ScrollProvider } from './contexts/scroll';
import { AppointmentProvider } from './contexts/appointment';
import { StepperProvider } from './components/@tem-ui/Stepper';
import { BottomSheetProvider } from './components/@tem-ui/BottomSheet';
import { DialogProvider } from './components/@tem-ui/Dialog/hooks';
import { CodePushManager } from './components/CodePushManager';
import { GeolocationProvider } from './components/@hello-ui/Adresses/Geolocation';
import { AdressesProvider } from './components/@hello-ui/Adresses';

import Navigator from './router/navigator';
import ApiContext from './contexts/api';
import ApiService from './services/api';
import {
  Dialog,
  Menu,
  MenuProvider,
  PrivacyPolicy,
  SafeAreaWrapper,
  SweetAlert,
  SweetAlertProvider,
} from './components/@tem-ui';

import { CustomThemeProvider, TopLoadingBar } from './components/@hello-ui';
import { GlobalStyle } from '~/components/@hello-ui/Theme/GlobalStyle';

import '~/store/auth.store';
import '~/store/global.store';
import '~/auth/strategies/deep-link/deep-link.store';
import { WebProviders } from './contexts/web';
import { CheckupProvider } from './contexts/checkup';
import { FakeComponent } from './components/FakeComponent';

if (DISABLE_CLARITY !== 'true') {
  initialize(CLARITY_MOBILE_HASH);
}

export const instanceApi = new ApiService({
  baseUrl: API_URL,
});

const App = (): JSX.Element => {
  return (
    <>
      {Platform.OS === 'web' && <GlobalStyle />}
      <StatusBar
        barStyle={`${Platform.OS === 'android' ? 'light' : 'dark'}-content` as StatusBarStyle}
      />
      <RecoilRoot>
        <ApiContext.Provider value={instanceApi}>
          <CheckupProvider>
            <WebProviders>
              <AuthProvider>
                <CustomThemeProvider>
                  <TemPayProvider>
                    <SweetAlertProvider>
                      <AdressesProvider>
                        <GeolocationProvider>
                          <StepperProvider>
                            <BottomSheetProvider>
                              <DialogProvider>
                                <AppointmentProvider>
                                  <MenuProvider>
                                    <ScrollProvider>
                                      <SafeAreaWrapper>
                                        <Navigator />
                                        <Dialog />
                                        <PrivacyPolicy />
                                        <SweetAlert />
                                        <Menu />
                                        <TopLoadingBar />
                                        <FakeComponent />
                                      </SafeAreaWrapper>
                                    </ScrollProvider>
                                    <CodePushManager />
                                  </MenuProvider>
                                </AppointmentProvider>
                              </DialogProvider>
                            </BottomSheetProvider>
                          </StepperProvider>
                        </GeolocationProvider>
                      </AdressesProvider>
                    </SweetAlertProvider>
                  </TemPayProvider>
                </CustomThemeProvider>
              </AuthProvider>
            </WebProviders>
          </CheckupProvider>
        </ApiContext.Provider>
      </RecoilRoot>
    </>
  );
};

export default App;
