import { ProgressRoot } from './ProgressRoot';
import { ProgressSection } from './ProgressSection';
import { ProgressTitle } from './ProgressTitle';
import { ProgressSteps } from './ProgressSteps';
import { ProgressBar } from './ProgressBar';

export * from './ProgressStep';

export const Progress = {
  Root: ProgressRoot,
  Section: ProgressSection,
  Title: ProgressTitle,
  Bar: ProgressBar,
  Steps: ProgressSteps,
};