import React, { useState } from 'react';

import { Linking, Platform, View } from 'react-native';

import { useTheme } from 'styled-components/native';

import { useNavigation } from '@react-navigation/native';

import { Webview } from './Components/Webview';
import {
  Button,
  Collapse,
  Dialog,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

import desktopBackground from '~/assets/images/benefitsTraining/desktop.png';
import mobileBackground from '~/assets/images/benefitsTraining/mobile.png';
import TrainingIcon from '~/assets/icons/muscle.svg';
import { useApi } from '~/hooks/api';

export const BenefitsTraining = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dialogState, setDialogState] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('');

  const theme = useTheme();
  const navigation = useNavigation();
  const api = useApi();

  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;

  const handleOpenTheMember = async () => {
    try {
      setIsLoading(true);

      const { url } = await api.getAccessTheMembers();

      if (Platform.OS === 'web') {
        await Linking.openURL(url);
      } else {
        setUrl(url);
        setDialogState(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <TrainingIcon height={24} width={24} fill={theme.colors.white} />,
          title: 'Plataforma de Treinos',
          onBack: () => {
            navigation.goBack();
          },
        }}>
        <View>
          <Typography variant="body2">
            Treine onde e quando quiser com a{' '}
            <Typography variant="bodyHighlight2">plataforma de treinos do Chico Salgado</Typography>
            , o <Typography variant="bodyItalic2">personal</Typography> das celebridades! Aulas
            guiadas por especialistas, treinos diários sem equipamentos e um programa completo para
            sua saúde física, mental e alimentar.
          </Typography>
        </View>

        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="desktop:pt-8">
              <Typography variant="body2">
                A plataforma <Typography variant="bodyHighlight2">Chico Salgado Team</Typography> é
                um hub completo de{' '}
                <Typography variant="bodyHighlight2">treinos funcionais</Typography>, com programas
                específicos para emagrecimento, ganho de massa muscular, condicionamento físico e
                bem-estar. São <Typography variant="bodyHighlight2">aulas curtas</Typography> e{' '}
                <Typography variant="bodyHighlight2">dinâmicas</Typography> (a partir de 18
                minutos), disponíveis 24h por dia, permitindo que você treine no seu ritmo, onde
                quiser.
              </Typography>

              <Typography variant="body2" className="mt-8">
                Além dos treinos diários, você terá acesso a uma comunidade exclusiva e módulos com
                especialistas renomados, incluindo{' '}
                <Typography variant="bodyHighlight2">Yoga</Typography>,{' '}
                <Typography variant="bodyHighlight2">Meditação</Typography>,{' '}
                <Typography variant="bodyHighlight2">Nutrição</Typography>,{' '}
                <Typography variant="bodyHighlight2">Saúde Mental</Typography> e{' '}
                <Typography variant="bodyHighlight2">muito mais</Typography>. Tudo pensado para uma{' '}
                <Typography variant="bodyHighlight2">transformação completa</Typography>, com
                qualidade e resultados reais!
              </Typography>
            </View>
          </Collapse>
        </View>

        <MobileAutoSpace />

        <View className="mb-24 mt-40 w-full items-center self-center mobile:mb-16 mobile:mt-24">
          <Typography variant="bodyHighlight2" className="text-center mobile:w-[328px]">
            Clique abaixo para utilizar seu benefício:
          </Typography>
        </View>

        <Button variant="primary" onPress={handleOpenTheMember} loading={isLoading}>
          Acessar
        </Button>
      </PageWithCard>

      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <Webview url={url} />
      </Dialog>
    </>
  );
};
