import React, { useMemo } from 'react';

import { View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList/CardList';
import ProfileIcon from '~/components/Icons/Profile';
import LockPasswordIcon from '~/components/Icons/LockPasswordIcon';
import PinIcon from '~/components/Icons/PinIcon';
import Family from '~/components/Icons/Family';
import LockIcon from '~/components/Icons/LockIcon';
import { useAuth } from '~/auth/legacy/useAuth';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { isPagBankOnix } from '~/helpers/pagbank';

export const Profile = (): JSX.Element => {
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
  const { checkIsEnabledFeature, isDependent, onixCode } = useAuth();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  const checkPagBank = isPagBankOnix(onixCode?.onix_code.toString());

  const profilePasswordIsVisible = !checkMercadoPago && !checkPagBank;

  const cards = useMemo(
    () =>
      [
        {
          icon: <ProfileIcon />,
          title: 'Dados Pessoais',
          description: 'Atualize seus dados cadastrais',
          onPress: () => navigation.navigate('ProfileData'),
          visible: true,
          iconSideOfTitle: true,
        },
        {
          icon: <PinIcon />,
          title: 'Endereço',
          description: 'Atualize seu endereço',
          onPress: () => navigation.navigate('ProfileAddress'),
          visible: true,
          iconSideOfTitle: true,
        },
        {
          icon: <Family />,
          title: 'Gerenciar Dependentes',
          description: 'Adicione, visualize ou edite seus dependentes',
          onPress: () => navigation.navigate('DependentsPresentation'),
          visible: !isDependent && checkIsEnabledFeature('dependents'),
          iconSideOfTitle: true,
        },
        {
          icon: <LockIcon />,
          title: 'Senha de Acesso ao APP',
          description: 'Atualize sua senha de acesso ao app',
          onPress: () => navigation.navigate('ProfilePassword'),
          visible: profilePasswordIsVisible,
          iconSideOfTitle: true,
        },
        {
          icon: <LockPasswordIcon />,
          title: 'Senha da Conta Saúde',
          description: 'Atualize sua senha para pagamentos',
          onPress: () => navigation.navigate('TemPayPassword'),
          visible: true,
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Meu Perfil',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <View className="pb-40" testID="profile-screen">
        <CardList data={cards} />
      </View>
    </PageWithCard>
  );
};
