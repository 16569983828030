import React, { useCallback } from 'react';
import { ListRenderItemInfo, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import * as S from './styles';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';
import { DependentsTypes } from '~/components/Dialog/Dependents/types';
import { useDialog } from '~/components/@tem-ui';
import { Typography } from '~/components/@hello-ui';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { Listing } from '~/components/@hello-ui/Listing';
import { useStepper } from '~/components/@tem-ui/Stepper';

export const DependentsList = ({ isCheckup }: { isCheckup?: boolean }): JSX.Element => {
  const theme = useTheme();

  const { tracking } = useTracking();
  const { closeDialog } = useDialog();
  const { setPatient, setAppointment } = useAppointment();
  const { dependents, user, isDependent } = useAuth();
  const { nextStep } = useStepper();
  const dependentsLimit = theme?.dependents_limit === '' ? 0 : theme?.dependents_limit;

  const activeDependents =
    dependents?.filter(({ status }, idx): boolean => {
      return status !== 3 && idx < parseInt(dependentsLimit);
    }) ?? [];

  const renderItem = useCallback(
    ({ item, isTitular }: { item: DependentsTypes; index: number; isTitular?: boolean }) => {
      const { name, cpf } = item;
      return (
        <S.WrapperItem
          onPress={() => {
            void tracking.addMovement({
              event: trackingEventType.ClickedOnPatient,
              metadata: {
                patient_info: { ...item, isDependent },
              },
            });

            setPatient({ ...item, isTitular, id: cpf.replace(/[^\d]/g, '') });
            if (isCheckup) {
              setAppointment({ isCheckup: true });
            }
            nextStep();
          }}>
          <S.WrapperBody>
            <Typography variant="bodyHighlight2" className="capitalize" color={theme.colors.title}>
              {name.toLowerCase()}
            </Typography>
            <Typography variant="body2" color={theme.colors.paragraph}>
              {isTitular ? 'Titular' : 'Dependente'}
            </Typography>
          </S.WrapperBody>
          <Icon name="chevron-right" size={24} color={theme?.colors?.primary} />
        </S.WrapperItem>
      );
    },
    [closeDialog],
  );

  const currentUser = useCallback(
    () => ({
      id: user?.id ?? '',
      name: user?.name ?? '',
      cpf: user?.cpf ?? '',
      birthdate: user?.birthdate ?? '',
      isTitular: true,
    }),
    [user],
  );

  return (
    <>
      <Listing
        data={[currentUser(), ...activeDependents]}
        extraHeight={45}
        renderItem={({ item, index }: ListRenderItemInfo<DependentsTypes>) =>
          renderItem({ item, index, isTitular: index === 0 })
        }
        useSeparator
        hideBackToTop
      />
    </>
  );
};
