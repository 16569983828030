import React, { useEffect } from 'react';

import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { Button, Typography } from '~/components/@hello-ui';
import { useCheckupContext } from '~/contexts/checkup';

export const AutoCheckup = () => {
  const theme = useTheme();
  const navigation = useNavigation();

  const { getSpecialities } = useCheckupContext();

  useEffect(() => {
    try {
      void getSpecialities();
    } catch (error) {
      console.error('Erro ao carregar especialidades:', error);
    }
  }, []);

  return (
    <View className="items-center">
      <Typography variant="bodyHighlight2" className="mb-16 mt-24 text-center">
        Solicite o benefício clicando no botão abaixo:
      </Typography>
      <Button
        variant="primary"
        icon={<Icon name="chevron-right" size={24} color={theme.colors.white} />}
        iconPosition="right"
        className="ml-0"
        onPress={() => navigation.navigate('ScheduleCheckup')}>
        Solicitar
      </Button>
    </View>
  );
};