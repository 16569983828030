import { clsx } from 'clsx';
import React, { type ReactElement } from 'react';
import { View } from 'react-native';

type ProgressSectionProps = {
  children: ReactElement | ReactElement[];
  closed?: boolean;
};

export const ProgressSection = ({ children, closed }: ProgressSectionProps) => {
  return <View className={clsx({ 'flex-1': closed })}>{children}</View>;
};