import React, { useEffect, useMemo, useState } from 'react';

import { useTheme } from 'styled-components/native';
import { BenefitsCardsTypes } from './types';
import { BenefitCards } from '~/screens/Dashboard/components/Benefits/components/BenefitCards/BenefitCards';
import { useAuth } from '~/auth/legacy/useAuth';
import { Spacing } from '~/components/Spacing';
import { Loader } from '~/components/@hello-ui';

import StethoscopeIcon from '~/components/Icons/Stethoscope';
import BusinessIcon from '~/components/Icons/BusinessIcon';
import Medicaments from '~/components/Icons/Medicaments';
import VideoCameraIcon from '~/components/Icons/VideoCameraIcon';
import Woman from '~/components/Icons/Woman';
import Plant from '~/components/Icons/Plant';
import Segurity from '~/components/Icons/Segurity';
import CircleGroup from '~/components/Icons/CircleGroup';
import House from '~/components/Icons/House';
import Finance from '~/components/Icons/Finance';
import { trackingEventType } from '~/contexts/tracking/types';
import TimeIcon from '~/components/Icons/TimeIcon';
import SchedulesAllTimeIcon from '~/components/Icons/ScheduleAllTime';
import PetIcon from '~/components/Icons/PetIcon';
import { DISCOUNT_MEDICINES_FEATURES } from '~/helpers/features/discount-medicines';

export const Benefits = (): JSX.Element => {
  const theme = useTheme();
  const { checkIsEnabledFeature, isDependent } = useAuth();

  const [benefitsToShow, setBenefitsToShow] = useState<BenefitsCardsTypes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const benefits: BenefitsCardsTypes[] = useMemo(
    () => [
      {
        title: 'Consulta por Vídeo',
        content: '24 horas',
        route: 'Schedule24h',
        names: ['telemedicine_24', 'medex_telemedina24', 'Brasil_Telemedicina24'],
        params: {
          toStepper: 0,
          fromPage: 'Dashboard',
        },
        icon: <SchedulesAllTimeIcon />,
      },
      {
        title: 'Consultas por Vídeo',
        content: 'Agendadas',
        route: 'VideoAppointmentsSchedule',
        names: ['telemedicine', 'psicologia_online'],
        icon: <VideoCameraIcon height={16} width={16} />,
      },
      {
        title: 'Benefício',
        content: 'Farmácia',
        route: 'PharmacyBenefit',
        names: ['medicine_discounts', 'subsidy_medicines', ...DISCOUNT_MEDICINES_FEATURES],
        icon: <Medicaments />,
      },
      {
        title: 'Buscar',
        content: 'Rede',
        route: 'BenefitsServicesConsultations',
        names: ['health_network', 'dentistry'],
        icon: <StethoscopeIcon height={16} width={16} />,
        tracking: trackingEventType.ClickedOnHealthNetwork,
      },
      {
        title: 'Benefício',
        content: 'Mulher',
        route: 'BenefitsWoman',
        names: [
          'bella_experts_woman',
          'bella_female_assistance',
          'beneficiolegal_welcome_woman',
          'bella_parental_assistance',
        ],
        icon: <Woman />,
      },
      {
        title: 'Benefício',
        content: 'Bem-estar',
        route: 'BenefitsWellBeing',
        names: [
          'Namu',
          'Namu_WebSSO',
          'medex_healthy_habits',
          'medex_saude_mental',
          'programa_mente_e_corpo',
          'psychological_teleorientation',
          'nutritional_teleorientation',
        ],
        icon: <Plant />,
      },
      {
        title: 'Serviços',
        content: '24 horas',
        route: 'Schedule24h',
        names: [
          'telemedicine_24',
          'Brasil_Telemedicina24',
          'teleorientation',
          'funeral',
          'residential_assistant',
          'medex_telemedina24',
        ],
        icon: <TimeIcon width={14} height={14} />,
      },
      {
        title: 'Coberturas',
        content: 'de Seguros',
        route: 'BenefitsInsurance',
        names: ['life_insurance', 'funeral', 'funeral_memorial', 'funeral_igs'],
        icon: <Segurity />,
      },
      {
        title: 'Assistência',
        content: 'Residencial',
        route: 'BenefitsResidentialAssistant',
        names: ['residential_assistant'],
        icon: <House />,
      },
      {
        title: 'Benefício',
        content: 'Financeiro',
        route: 'BenefitsFinancial',
        names: ['capitalization_title', 'financial_teleorientation', 'discount_club'],
        icon: <Finance />,
      },
      {
        title: 'Assistência',
        content: 'Funeral',
        route: 'BenefitsFunerals',
        names: ['funeral', 'funeral_memorial', 'funeral_igs'],
        icon: <CircleGroup />,
        tracking: trackingEventType.ClickedOnfuneralAssistance,
      },
      {
        title: 'Assistência',
        content: 'Pet',
        route: 'BenefitsPetAssistance',
        names: ['pet_assistance'],
        icon: <PetIcon />,
      },
      {
        title: 'Assistência',
        content: 'Empresarial',
        route: 'BenefitsBusinessAssistant',
        names: ['business_assistance'],
        icon: <BusinessIcon height={16} width={16} />,
      },
    ],
    [],
  );

  useEffect(() => {
    setLoading(true);
    const visibleBenefits: BenefitsCardsTypes[] = [];

    benefits.forEach((benefit) => {
      const { names } = benefit;

      if (names.some((name) => checkIsEnabledFeature(name))) {
        visibleBenefits.push(benefit);
      }
    });

    setBenefitsToShow(visibleBenefits);
    setLoading(false);
  }, [isDependent]);

  const spacingSize = theme?.isMobile ? 20 : 100;

  return (
    <>
      {loading || isDependent === null ? (
        <Spacing bottom={spacingSize} top={spacingSize}>
          <Loader variant="circle-loader" />
        </Spacing>
      ) : (
        <BenefitCards benefits={benefitsToShow} />
      )}
    </>
  );
};
