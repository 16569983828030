import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/core';
import { useRoute } from '@react-navigation/native';
import { scheduleType } from './components/ScheduleType';
import * as S from './styles';
import { Stepper } from '~/components/@hello-ui/Stepper/Stepper';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { useAppointment } from '~/hooks/appointment';
import { useUploader } from '~/components/@tem-ui/Uploader';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { Typography } from '~/components/@hello-ui';

const types = {
  'consulta-presencial': 'Solicitação de consulta',
  'consulta-video': 'Solicitação de consulta por vídeo',
  exames: 'Solicitação de exames',
  'consulta-24-horas': 'Consulta por Vídeo 24 Horas',
  checkup: 'Solicitação de Check-Up',
};

export const ScheduleAppointment = (): JSX.Element => {
  const navigation = useNavigation();
  const {
    scrollDisabled,
    customPadding,
    currentStep,
    stepper,
    finalizeSteps,
    stepData,
    setCurrentStepState,
    toStepper,
    overrideBodyMaxWidth,
  } = useStepper();

  const { params } = useRoute<UseRoute<'ScheduleAppointment'>>();
  const { isDeepLink, feature } = DeepLink.useParams();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments, appointment } = useAppointment();
  const { clearUploader } = useUploader();

  useEffect(() => {
    clearUploader();
    clearAppointments();
  }, []);

  const title = stepData?.customTitle ? stepData?.customTitle : stepData?.title;

  useEffect(() => {
    if (params?.toStepper !== undefined) {
      toStepper(params.toStepper);
    }
  }, [params]);

  const handleGoBack = () => {
    if (currentStep >= 0 && stepper !== null) {
      appointment?.notFoundAttatchment ? clearAppointments() : showCancelAlert();
    } else {
      if (isDeepLink) {
        DeepLink.sendEvent(DeepLinkEvent.Cancel);
      } else {
        finalizeSteps();
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Dashboard');
      }
    }
  };

  const handleGoHome = () => {
    if (currentStep >= 0 && stepper !== null) {
      appointment?.notFoundAttatchment ? clearAppointments() : showCancelAlert(true);
    } else {
      if (isDeepLink) {
        DeepLink.sendEvent(DeepLinkEvent.Cancel);
      } else {
        finalizeSteps();
        navigation.navigate('Dashboard');
      }
    }
  };

  const showCancelAlert = (goHome?: boolean) => {
    showSweetAlert(
      'Cancelar solicitação',
      <Typography variant="body2">
        Ao clicar <Typography variant="bodyHighlight2Bold">SIM</Typography> você cancelará a
        solicitação em andamento.
      </Typography>,
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              clearUploader();
              finalizeSteps();
              setCurrentStepState(0);
              clearAppointments();
              goHome ? navigation.navigate('Dashboard') : undefined;

              if (params?.toStepper !== undefined && isDeepLink) {
                DeepLink.sendEvent(DeepLinkEvent.Cancel);
                toStepper(params.toStepper);
              }

              showSweetAlert(
                'Solicitação cancelada',
                'Você pode fazer uma nova solicitação a qualquer momento.',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  return (
    <PageWithCard
      simpleOptions={{
        onBack:
          currentStep === 0 && isDeepLink && !appointment?.notFoundAttatchment
            ? undefined
            : handleGoBack,
        onHome: !stepData || stepper === null ? undefined : handleGoHome,
        title: isDeepLink ? types[feature] : title ?? 'Solicitação de agendamento',
      }}
      overrideBodyMaxWidth={overrideBodyMaxWidth}
      overrideScrollEnabled={!scrollDisabled}
      overridePadding={customPadding}>
      <S.StepperContainer>
        <Stepper
          title="O que você precisa?"
          description="Selecione o serviço que deseja solicitar o agendamento:"
          items={scheduleType()}
          validateDependentFeatures
        />
      </S.StepperContainer>
    </PageWithCard>
  );
};
