import clsx from 'clsx';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useAuth } from '~/auth/legacy/useAuth';
import {
  Button,
  Collapse,
  MobileAutoSpace,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { Footer } from '~/components/Footer';
import CalendarIcon from '~/components/Icons/CalendarIcon';
import ClinicIcon from '~/components/Icons/ClinicIcon';
import ExamIcon from '~/components/Icons/Exam';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import TimeIcon from '~/components/Icons/TimeIcon';
import { isConectaOnix } from '~/helpers/conecta';
import { isMercadoPagoOnix, MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { useApi } from '~/hooks/api';
import { ProgressBar } from '~/screens/AppointmentDetails/components/ProgressBar';
import { CHECKUP_STEPS } from '~/screens/AppointmentDetails/components/Steps/checkup';
import { HelpText } from '~/screens/MyAppointments/components/AppointmentDetails/HelpText';
import { Steps } from '~/screens/MyAppointments/components/Steps/Steps';
import { makeInfoByType } from '~/screens/MyAppointments/helpers/make-info-by-type';
import {
  makeTextByCheckupStatus,
  makeTextByStatus,
} from '~/screens/MyAppointments/helpers/make-text-by-status';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { useAuthStore } from '~/store/auth.store';
import { isAppointmentForHolder } from '~/utils/isAppointmentForHolder';
import { toCamelCase } from '~/utils/strings';
import { RequestDetails } from '~/screens/AppointmentDetails/components/RequestDetails';
import { AlertIconOutlined } from '~/components/Icons/AlertIconOutlined';
import { Spacing } from '~/components/Spacing';
import { Wrapper } from '../styles';
import { capitalize, currency } from '~/utils/strings';
import { pt } from 'date-fns/locale';

export const InProgressAppointment = () => {
  const theme = useTheme();
  const api = useApi();
  const { myAppointment, setSelectedAppointment } = useMyAppointments();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { onixCode, isDependent } = useAuth();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  const {
    clinic_address,
    preferred_periods,
    specialty,
    specialty_online,
    status: actualStatus,
    statusStage,
    online_process,
    type,
    created_at,
    address,
    history,
    isFinished,
  } = myAppointment!;

  const statusType = makeTextByStatus(theme);

  const appointmentType = makeInfoByType(theme);

  const checkupStatus = makeTextByCheckupStatus(theme, statusStage);

  const stages = {
    appointment: 'Primeira Consulta',
    exam: 'Exames',
    recurrence: 'Consulta de Retorno',
  };

  const cancelAppointment = async () => {
    try {
      showSweetAlert('Cancelando solicitação...', '', 'success', false, false, {
        layout: 'helloUi',
      });
      await api.cancelAppointmentByID(
        myAppointment?.id as unknown as string,
        myAppointment?.source,
      );

      showSweetAlert('Tudo certo!', 'Sua solicitação foi cancelada', 'success', false, false, {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Ok',
            variant: 'primary',
            onPress: () => {
              hideSweetAlert();
              setSelectedAppointment(null);
            },
          },
        ],
      });
    } catch (error) {
      console.error(error);
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível cancelar sua solicitação. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );
    }
  };

  const askForConfirmation = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja cancelar solicitação de agendamento?',
      'warning',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: cancelAppointment,
          },
        ],
      },
    );
  };

  const getCurrentStep = () => {
    let bindings: Record<string, number> = {
      requested: 0,
      accepted: 1,
    };

    if (['medical', 'presential', 'exam'].includes(type)) {
      bindings = {
        requested: 0,
        suggested: 1,
        scheduling: 2,
        accepted: 3,
      };
    }

    return bindings[actualStatus] ?? 0;
  };

  const getStepIcons = () => {
    if (['medical', 'presential', 'exam'].includes(type)) {
      return [type === 'exam' ? ExamIcon : StethoscopeIcon, ClinicIcon, TimeIcon, CalendarIcon];
    }

    return [TimeIcon, CalendarIcon];
  };

  const isToShowDeadline = () => {
    const currentStep = getCurrentStep();
    return ['medical', 'presential', 'exam'].includes(type) && currentStep < 3;
  };

  const isToShowAsterisk = () => {
    const currentStep = getCurrentStep();
    return isToShowDeadline() && currentStep > 1;
  };

  const getAddress = () => {
    if (address) {
      return `${clinic_address ? `Unidade ${clinic_address} - ` : ''}${address.neighborhood}, ${
        address.city
      }, ${address.state}`;
    } else {
      return '-';
    }
  };

  const getFormattedDate = (info_agendamento) => {
    const formattedData = new Intl.DateTimeFormat('pt-BR', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(
      new Date(info_agendamento?.data_atendimento + ' ' + info_agendamento?.hora_atendimento),
    );

    return formattedData.charAt(0).toUpperCase() + formattedData.slice(1);
  };

  const getRequestDate = () => {
    if (created_at) {
      const parsedDate = parse(created_at, 'yyyy-MM-dd', new Date(), { locale: ptBR });
      return format(parsedDate, "d 'de' MMMM 'de' yyyy", {
        locale: ptBR,
      });
    } else {
      return '-';
    }
  };

  const getPreferredPeriods = () => {
      if (typeof preferred_periods === "string") {
        return JSON.parse(preferred_periods);
      }
      return preferred_periods;
  };

  const checkCancel = () => {
    if (type === 'checkup' || myAppointment?.source !== 'conexa') {
      return (
        <>
          <Typography variant="body2" className="mb-24 mobile:mb-18">
            Se precisar cancelar ou reagendar seu atendimento fale com nossa Central de Atendimento.
          </Typography>
        </>
      );
    }

    if (myAppointment?.source === 'conexa') {
      return (
        <>
          <Typography variant="body2" className="mb-24 mobile:mb-16">
            Se precisar cancelar seu atendimento, é só clicar no botão abaixo.
          </Typography>
          <Button variant="disabled" onPress={askForConfirmation}>
            Cancelar solicitação
          </Button>
        </>
      );
    }

    return <></>;
  };

  const totalSteps = CHECKUP_STEPS.reduce((accumulator, current) => {
    return accumulator + current.steps.length;
  }, 0);

  const steps = {
    appointment: {
      scheduling: 0,
      scheduled: 1,
      closed: 1,
    },
    exam: {
      medicalRequest: 2,
      scheduling: 3,
      scheduled: 4,
      closed: 4,
    },
    recurrence: {
      waitingResults: 5,
      scheduling: 6,
      scheduled: 7,
      closed: 7,
    },
  };
  const isRecurrenceFinalStatus =
    statusStage === 'recurrence' && (actualStatus === 'scheduling' || actualStatus === 'scheduled');
  const isHolder = isAppointmentForHolder(myAppointment!!, useAuthStore().user!!);

  return (
    <>
      {type === 'checkup' ? (
        <ProgressBar
          data={CHECKUP_STEPS}
          currentStep={steps[statusStage][actualStatus] as number}
          status={actualStatus === 'closed' ? 'closed' : 'in-progress'}
        />
      ) : (
        <Steps currentStep={getCurrentStep()} stepIcons={getStepIcons()} />
      )}
      <View
        className={clsx('mb-24', {
          'mt-40 mobile:mt-32': type !== 'checkup',
          'mt-6': type === 'checkup',
        })}>
        {type === 'checkup' ? (
          <>
            {actualStatus !== 'closed' ? (
              <>
                <Typography variant="body2" className="mb-8 text-sm">
                  Check-Up Médico
                </Typography>

                <Typography variant="body2" className="mb-8 text-sm">
                  Etapa {steps[statusStage][actualStatus] + 1} de {totalSteps}:{' '}
                  <Typography variant="bodyHighlight2" className="text-sm">
                    {checkupStatus[actualStatus]?.text}
                  </Typography>
                </Typography>

                <Typography variant="body2" className="text-sm">
                  Protocolo:{' '}
                  <Typography variant="bodyHighlight2" className="text-sm">
                    {myAppointment?.protocol}
                  </Typography>
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" className="mb-8 text-sm">
                  Check-Up Médico
                </Typography>
                <View className="flex-1 items-center justify-center rounded-lg bg-background-gray p-8">
                  <Typography variant="bodyHighlight2" color={theme.colors.paragraph}>
                    Encerrado
                  </Typography>
                </View>
              </>
            )}
          </>
        ) : (
          <>
            <Typography variant="body2">
              Etapa {getCurrentStep() + 1} de {getStepIcons().length}:
            </Typography>
            <Typography variant="bodyHighlight2" className="mb-8 mobile:mb-4">
              {statusType[actualStatus]?.text}
            </Typography>
            <Typography variant="body2">
              Protocolo: <Typography variant="bodyHighlight2">{myAppointment?.protocol}</Typography>
            </Typography>
          </>
        )}
      </View>
      {actualStatus !== 'closed' && (
        <Collapse title="O que fazer agora?">
          <HelpText type={type} currentStep={getCurrentStep()} />
          {type === 'checkup' && (
            <>
              <View>{checkupStatus[actualStatus].paragraph()}</View>

              {!isRecurrenceFinalStatus ? (
                <Wrapper
                  style={{
                    backgroundColor: '#FEEED1',
                    marginTop: 10,
                    padding: 10,
                  }}>
                  <View style={{ alignItems: 'flex-start' }}>
                    <View
                      style={{
                        flexDirection: 'row',
                      }}>
                      <AlertIconOutlined width={30} height={30} />
                      <Typography variant="body2" color={theme.colors.orange14}>
                        Importante
                      </Typography>
                    </View>
                    <Typography variant="bodyHighlight2" color={theme.colors.black70}>
                      Não é necessário solicitar um novo agendamento{' '}
                      <Typography variant="body2">
                        para cada etapa do seu Check-Up Médico.
                      </Typography>{' '}
                    </Typography>
                    <Spacing top={10}>
                      <Typography variant="body2" color={theme.colors.black70}>
                        Acompanhe o agendamento da sua primeira consulta, exames e consulta de
                        retorno nesta mesma solicitação{' '}
                      </Typography>
                    </Spacing>
                  </View>
                </Wrapper>
              ) : (
                <></>
              )}
            </>
          )}
        </Collapse>
      )}
      <Collapse title="Detalhes da solicitação">
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Protocolo
          </Typography>
          <Typography variant="body2">{myAppointment?.protocol}</Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Tipo
          </Typography>
          <Typography variant="body2">
            {appointmentType[online_process]
              ? appointmentType[online_process]?.description
              : appointmentType[type]?.description ?? '-'}
          </Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Especialidade
          </Typography>
          <Typography variant="body2">
            {specialty?.name ?? specialty_online?.name ?? '-'}
          </Typography>
        </View>
        <View className="mb-16">
          <Typography variant="body2" color="placeholder">
            Para quem
          </Typography>
          <Typography variant="body2">{`${myAppointment?.patient?.name} - ${isHolder ? 'Titular' : 'Dependente'}`}</Typography>
        </View>
        {myAppointment?.type !== 'online' && (
          <View className="mb-24">
            <Typography variant="body2" color="placeholder">
              Meu local
            </Typography>
            <Typography variant="body2">
              {address?.neighborhood + ', ' + address?.state + ' - ' + address?.city}
            </Typography>
          </View>
        )}
        {myAppointment?.type !== 'telemedicine' &&
          myAppointment?.type !== 'online' &&
          myAppointment?.type !== 'checkup' && (
            <View className="mb-16">
              <Typography variant="body2" color="placeholder">
                Preferência de endereço
              </Typography>
              <Typography variant="body2">{getAddress()}</Typography>
            </View>
          )}
        {myAppointment?.type !== 'checkup' && (
          <>
            <Typography variant="body2" color="placeholder" className="mb-8">
              Preferência de dia e período
            </Typography>
            <View className="mb-16">
            {
              getPreferredPeriods().length === 0
                ? '-'
                :
                getPreferredPeriods().map((period) => (
                    <Typography variant="body2">{period.toString()}</Typography>
                  ))}
            </View>
          </>
        )}
        <View className="mb-24">
          <Typography variant="body2" color="placeholder">
            Data da solicitação
          </Typography>
          <Typography variant="body2">{getRequestDate()}</Typography>
        </View>

        <View className="mb-24">
          {type === 'checkup' &&
            history?.map(
              (
                {
                  status,
                  intergrallStatus,
                  local_atendimento,
                  info_agendamento,
                  isBeforeActual,
                  stage,
                  isSameStage,
                },
                index,
              ) =>
                actualStatus && (
                  <RequestDetails.Step title={stages[stage]} key={`step_${index}`}>
                    <RequestDetails.StepItem title="Status">
                      {isSameStage ? checkupStatus[intergrallStatus]?.text : 'Concluído'}
                    </RequestDetails.StepItem>
                    {intergrallStatus === 'scheduled' ? (
                      <RequestDetails.StepItem title="Endereço">{`${local_atendimento?.endereco}, ${local_atendimento.numero}, ${local_atendimento.estado} - ${local_atendimento.cidade}`}</RequestDetails.StepItem>
                    ) : (
                      <></>
                    )}
                    {intergrallStatus === 'scheduled' ? (
                      <RequestDetails.StepItem title="Dia e Horário">
                        {info_agendamento?.data_atendimento !== '' &&
                          getFormattedDate(info_agendamento)}
                      </RequestDetails.StepItem>
                    ) : (
                      <></>
                    )}
                  </RequestDetails.Step>
                ),
            )}
        </View>
      </Collapse>
      {!isMercadoPagoOnix(String(onixCode?.onixCode)) && actualStatus !== 'closed' && (
        <Collapse title={type === 'checkup' ? 'Reagendar ou cancelar' : 'Cancelamento'}>
          {checkCancel()}
        </Collapse>
      )}
      <MobileAutoSpace />

      {actualStatus === 'closed' && (
        <>
          <Typography variant="bodyHighlight2" className="mt-24 text-sm">
            Continue se cuidando!
          </Typography>
          <Typography variant="body2" className="mb-24 mt-2 text-sm mobile:mb-16">
            Conte com a gente se precisar de um novo atendimento, estaremos por aqui :)
          </Typography>
        </>
      )}

      {!theme.isMobile && !checkMercadoPago && (
        <Typography variant="bodyHighlight2" className="mt-24 text-center">
          Dúvidas? Fale conosco:
        </Typography>
      )}
      {!checkMercadoPago && (
        <View className="mt-16 mobile:mt-24">
          <Footer
            variant="simple-phone-footer"
            showButtonOnMobile
            textButtonOnMobile="Dúvidas? Fale conosco:"
          />
        </View>
      )}
    </>
  );
};