import React from 'react';

import { Platform } from 'react-native';

import WebView from 'react-native-webview';

type WebviewProps = {
  url: string;
};

export const Webview = ({ url }: WebviewProps) => {
  return Platform.OS !== 'web' ? (
    <WebView
      nestedScrollEnabled={true}
      scrollEnabled={true}
      javaScriptEnabled={true}
      overScrollMode="always"
      source={{ uri: url }}
      style={{ flex: 1, minHeight: 200, height: 650, opacity: 0.99 }}
    />
  ) : (
    <iframe src={url} style={{ width: '100%', height: '80vh', border: 'none' }} />
  );
};
