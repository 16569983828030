import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Divider } from 'react-native-paper';
import { Linking, TouchableOpacity, View } from 'react-native';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { CardButton, PageWithCard, Typography } from '~/components/@hello-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { PAGBANK_ONIX } from '~/helpers/pagbank';

export const RegulationAndPrivacy = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const api = useApi();

  const { tracking } = useTracking();

  const [display, setDisplay] = useState<boolean>(false);
  const [regulationLink, setRegulationLink] = useState<string>('');
  const [privacyLink, setPrivacyLink] = useState<string>('');
  const { onixCode } = useAuth();
  const isPagBankOnix = PAGBANK_ONIX.includes(String(onixCode.onix_code));

  useEffect(() => {
    if (regulationLink === '') {
      void api.getRegulation().then((product) => {
        setRegulationLink(product.data.arquivo_contrato);
      });
    }

    if (privacyLink === '') {
      void api.returnPrivacyPolicy().then((privacy) => {
        setPrivacyLink(privacy.data[0].url);
      });
    }
  }, [regulationLink, privacyLink]);

  return (
    <PageWithCard
      simpleOptions={{
        title: isPagBankOnix ? 'Regulamentos' : 'Regulamentos e Privacidade',
        onBack: () => navigation.navigate('Dashboard'),
      }}>
      <Typography variant="title" color="black">
        Transparência e Segurança
      </Typography>
      <View className="mb-40 mt-8 mobile:mb-16 mobile:mt-4">
        <Typography variant="bodyHighlight2">
          Conheça nossos termos, regulamentos e políticas
        </Typography>
      </View>
      {display && (
        <>
          <Typography variant="bodyHighlight2">Regulamentos</Typography>
          <Typography variant="body2">
            São as regras e condições de uso do produto contratado, como: benefícios estão
            contemplados, detalhes destes benefícios, condições de uso e demais informações legais.
          </Typography>
          {!isPagBankOnix && (
            <View className="mb-24 mt-16 mobile:mb-16 mobile:mt-8">
              <Typography variant="bodyHighlight2">Políticas de Privacidade</Typography>
              <Typography variant="body2">
                São as práticas adotadas em relação aos seus dados quando você adquire um de nossos
                programas de saúde e acessa nosso site ou aplicativo. Entenda como utilizamos os seus
                dados e para quais finalidades.
              </Typography>
            </View>
          )}
        </>
      )}
      <TouchableOpacity
        onPress={() => {
          setDisplay(!display);
        }}>
        <Typography variant="body2" color={theme.colors.primary}>
          Ver {display ? 'menos ' : 'mais '}
          <Icon
            name={display ? 'chevron-up' : 'chevron-down'}
            size={20}
            color={theme?.colors.primary}
          />
        </Typography>
      </TouchableOpacity>
      <View className="mb-40 mt-24 mobile:mb-24 mobile:mt-16">
        <Divider />
      </View>
      <CardButton
        variant="xlarge"
        title="Regulamentos"
        description="Regras e condições de uso"
        onPress={() => {
          void tracking.addMovement({
            event: trackingEventType.ClickedOnRegulation,
          });
          regulationLink ? Linking.openURL(regulationLink) : '';
        }}
      />
      {!isPagBankOnix && (
        <View className="mt-16 mobile:mt-8">
          <CardButton
            variant="xlarge"
            title="Políticas de Privacidade"
            description="Coleta e utilização de dados"
            onPress={() => {
              void tracking.addMovement({
                event: trackingEventType.ClickedOnPrivacyPolicy,
              });
              Linking.openURL('https://www.temsaude.com.br/politica-de-privacidade.html');
            }}
          />
        </View>
      )}
    </PageWithCard>
  );
};
