import React, { useCallback, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import * as yup from 'yup';
import { isCpf } from 'iscpf';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { View } from 'react-native';
import axios from 'axios';
import {
  Button,
  Input,
  MobileAutoSpace,
  PageWithCard,
  RadioBoxGroup,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import {
  BIRTHDATE_MASK,
  CPF_MASK,
  NAME_CANNOT_CONTAIN_ONLY_BLANKS,
  TELPHONE_MASK,
  EMAIL_MASK,
} from '~/helpers/mask-regex-patterns';
import { isBirthdateValid, validatePhone } from '~/utils/validations';
import { useAuth } from '~/auth/legacy/useAuth';
import { trackingEventType } from '~/contexts/tracking/types';
import { mapDependent } from '~/helpers';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';
import { AuthService } from '~/auth/auth-service';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Digite um nome')
    .matches(NAME_CANNOT_CONTAIN_ONLY_BLANKS, 'Nome não pode conter apenas espaços em branco')
    .test(
      'two-words',
      'Digite o nome completo',
      (value = '') => value.trim().split(' ').length > 1,
    ),
  cpf: yup
    .string()
    .min(14, 'Digite todos os dígitos do seu CPF')
    .test({ test: (value = '') => isCpf(value), message: 'CPF Inválido' })
    .matches(CPF_MASK, 'CPF Inválido')
    .required(),
  birthdate: yup
    .string()
    .matches(BIRTHDATE_MASK, 'Digite uma data válida')
    .test({
      test: (value = '') => isBirthdateValid(value),
      message: 'Digite uma data válida',
    })
    .required('Digite uma data'),
  Telefone: yup
    .string()
    .matches(TELPHONE_MASK, 'Digite um número válido')
    .required('Digite um número de celular'),
  email: yup.string().matches(EMAIL_MASK, 'Digite um e-mail válido').required('Digite um e-mail'),
});

export const CreateDependents = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { user } = useAuth();
  const api = useApi();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { tracking } = useTracking();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      sexo: 'FEMININO',
      cpf: '',
      cpfTitular: user?.cpf,
      birthdate: '',
      Telefone: '',
      email: '',
    },
  });

  useFocusEffect(
    useCallback(() => {
      reset();
    }, []),
  );

  const dependentsLimit = theme.dependents_limit === '' ? 0 : theme.dependents_limit;

  const callApiDependents = async (values: any) => {
    try {
      await api.createDependent(values);

      await AuthService.updateDependents();

      showSweetAlert('Tudo certo!', 'Dependente cadastrado(a)', 'success', false, false, {
        layout: 'helloUi',
        buttons: [
          {
            variant: 'primary',
            text: 'Ok',
            onPress: () => {
              hideSweetAlert();
              navigation.navigate('Dependents');
            },
          },
        ],
      });
      reset();

      void tracking.addMovement({
        event: trackingEventType.AddedDependent,
      });
    } catch (e) {
      console.error(e);

      void tracking.addMovement({
        event: trackingEventType.AddedDependent,
        metadata: { error: { message: 'Erro ao cadastrar', reason: e?.message } },
      });
      let errorMessage = 'Não foi possível criar o dependente. Tente novamente mais tarde';
      if (axios.isAxiosError(e)) {
        const statusCode = e.response?.status?.toString();
        if (statusCode === '436') {
          errorMessage = 'O CPF informado já possui um cadastro ativo para este produto';
        }
      }
      showSweetAlert('Ops, algo deu errado', errorMessage, 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'OK',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForm = async (values: any) => {
    if (!validatePhone(values.Telefone)) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Telefone inválido, informe um número válido',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } else {
      setLoading(true);
      try {
        const {
          data: { result },
        } = await api.getDependents(user?.cpf);
        const resultLength = result?.filter((d) => d.COD_STATUS !== 3).length;

        if (parseInt(dependentsLimit) > resultLength) {
          await callApiDependents(values);
        } else {
          void tracking.addMovement({
            event: trackingEventType.AddedDependent,
            metadata: {
              error: 'Número máximo de dependentes',
            },
          });

          showSweetAlert(
            'Ops, algo deu errado',
            'Você já possui o numero máximo de dependentes',
            'error',
            false,
            false,
            {
              layout: 'helloUi',
              touchOutside: false,
              buttons: [
                {
                  text: 'OK',
                  variant: 'primary',
                  onPress: () => hideSweetAlert(),
                },
              ],
            },
          );
        }
      } catch (err) {
        await callApiDependents(values);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Cadastrar Dependentes',
        onBack: () => navigation.navigate('Dependents'),
      }}>
      <Typography variant="subtitle" className="mb-24 mobile:mb-16">
        Cadastro
      </Typography>
      <View className="mb-8">
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={(event) => {
                onChange(
                  event === ' '
                    ? event.replace(/( ){1,}/g, '')
                    : event.replace(/[0-9]/g, '').replace(/( ){2,}/g, ''),
                );
              }}
              placeholder="Digite"
              label="Nome Completo"
              error={error?.message}
            />
          )}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="cpf"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              variant="mask"
              mask="999.999.999-99"
              onChangeText={onChange}
              placeholder="Digite"
              label="CPF"
              error={error?.message}
              keyboardType="numeric"
            />
          )}
        />
      </View>
      <Controller
        name="sexo"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RadioBoxGroup
            data={[
              { label: 'Feminino', value: 'FEMININO' },
              { label: 'Masculino', value: 'MASCULINO' },
              { label: 'Prefiro não informar', value: 'INDIFERENTE' },
            ]}
            vertical={theme.isMobile}
            value={value}
            onChange={onChange}
            error={error}
            label="Sexo Biológico"
          />
        )}
      />

      <View className="mb-8">
        <Controller
          name="birthdate"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              mask="99/99/9999"
              variant="mask"
              placeholder="Digite"
              label="Data de Nascimento"
              error={error?.message}
              keyboardType="numeric"
              onSubmitEditing={handleSubmit(handleForm)}
            />
          )}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              placeholder="Digite um e-mail"
              label="E-mail"
              error={error?.message}
              onSubmitEditing={handleSubmit(handleForm)}
            />
          )}
        />
      </View>

      <Controller
        name="Telefone"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            mask="(99) 9 9999-9999"
            onChangeText={onChange}
            variant="mask"
            placeholder="Digite"
            label="Celular"
            error={error?.message}
            keyboardType="phone-pad"
            onSubmitEditing={handleSubmit(handleForm)}
          />
        )}
      />
      <MobileAutoSpace heightMobile={40} heightDesktop={40} />
      <Button
        variant="primary"
        onPress={handleSubmit(handleForm)}
        loading={loading}
        disabled={loading || !isValid}>
        Cadastrar
      </Button>
    </PageWithCard>
  );
};
