import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { isCpf } from 'iscpf';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, parseISO } from 'date-fns';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { useSweetAlert } from '~/components/@tem-ui';
import { useAuth } from '~/auth/legacy/useAuth';
import { useApi } from '~/hooks/api';
import { useTracking } from '~/services/tracking';
import { trackingEventType } from '~/contexts/tracking/types';
import { differenceOldAndNewValue } from '~/components/@tem-ui/Uploader/utils';
import { Button, Input, MobileAutoSpace, RadioBoxGroup } from '~/components/@hello-ui';
import { AuthService } from '~/auth/auth-service';
import { UseRoute } from '~/@types/navigation/routes-helpers';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { validatePhone } from '~/utils/validations';

const schema = yup.object().shape({
  name: yup.string().required('Digite um nome'),
  social_name: yup.string().optional(),
  document_number: yup
    .string()
    .min(14, 'Digite todos os dígitos do seu CPF')
    .test({ test: (value = '') => isCpf(value), message: 'CPF Inválido' })
    .required(),
  email: yup.string().email('Digite um email válido').required('Digite um email'),
  phone: yup
    .string()
    .test({
      test: (value = '') => value.match(/\d/g)?.length === 11,
      message: 'Digite um celular válido',
    })
    .required('Digite um celular'),
  birthdate: yup.string().min(10, 'Digite uma data válida').required('Digite uma data'),
  gender: yup
    .string()
    .oneOf(['Masculino', 'Feminino', 'Indiferente'], 'Escolha um sexo')
    .required('Escolha um sexo'),
});

export const UpdateProfileDataForm = () => {
  const api = useApi();
  const navigation = useNavigation();
  const { params } = useRoute<UseRoute<'UpdateProfileData'>>();

  const { tracking } = useTracking();
  const { user } = useAuth();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const oldValues = {
    name: user.name,
    social_name: user.social_name ?? undefined,
    document_number: user.cpf,
    email: user.email,
    phone: user.telephone_1,
    birthdate: format(parseISO(user?.birthdate?.split('T')[0]), 'dd/MM/yyyy'),
    gender: user.gender.charAt(0).toUpperCase() + user.gender.slice(1),
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: oldValues,
  });

  const handleForm = (values: any) => {
    if (!validatePhone(values.phone)) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Telefone inválido, informe um número válido',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } else {
      setLoading(true);
      api
        .updateProfile(values)
        .then(async () => {
          await AuthService.updateProfileData();

          oldValues.phone = oldValues.phone.replace(/[\W_|\s]/g, '');
          values.phone = values.phone.replace(/[\W_|\s]/g, '');

          const diff = differenceOldAndNewValue({
            oldValue: oldValues,
            newValue: values,
          });

          void tracking.addMovement({
            event: trackingEventType.UpdatedMyProfile,
            metadata: { changes: diff },
          });

          DeepLink.sendEvent(DeepLinkEvent.Success);

          showSweetAlert('Tudo certo ;)', 'Seus dados foram atualizados', 'success', false, false, {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          });
          setLoading(false);

          if (params?.returnPage) {
            navigation.navigate(params.returnPage, { keepState: true });
          } else {
            navigation.navigate('ProfileData');
          }
        })
        .catch((e) => {
          void tracking.addMovement({
            event: trackingEventType.ErrorUpdatingMyProfile,
            metadata: { error: { message: 'Erro ao atualizar', reason: e.message } },
          });

          showSweetAlert('Ops, algo deu errado', 'Erro ao atualizar', 'error', false, false, {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          });
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label="Nome"
            error={error?.message ?? ''}
            value={value}
            isDisabled={true}
            onChangeText={onChange}
            placeholder="Digite"
            onSubmitEditing={handleSubmit(handleForm)}
          />
        )}
      />
      <Controller
        name="social_name"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            label="Nome Social"
            error={error?.message ?? ''}
            value={value}
            isDisabled={true}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(handleForm)}
          />
        )}
      />
      <Controller
        name="document_number"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            mask="999.999.999-99"
            placeholder="Digite"
            label="CPF"
            isDisabled={true}
            keyboardType="numeric"
            onSubmitEditing={handleSubmit(handleForm)}
            variant="mask"
            error={error?.message ?? ''}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            placeholder="Digite"
            label="E-mail"
            keyboardType="email-address"
            onSubmitEditing={handleSubmit(handleForm)}
            error={error?.message ?? ''}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            mask="(99) 9 9999-9999"
            onChangeText={onChange}
            placeholder="Digite"
            label="Celular"
            keyboardType="phone-pad"
            onSubmitEditing={handleSubmit(handleForm)}
            variant="mask"
            error={error?.message ?? ''}
          />
        )}
      />
      <Controller
        name="birthdate"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            onChangeText={onChange}
            mask="99/99/9999"
            placeholder="Digite"
            label="Data de Nascimento"
            keyboardType="numeric"
            isDisabled={true}
            onSubmitEditing={handleSubmit(handleForm)}
            variant="mask"
            error={error?.message ?? ''}
          />
        )}
      />

      <Controller
        name="gender"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <RadioBoxGroup
              data={[
                { label: 'Feminino', value: 'Feminino' },
                { label: 'Masculino', value: 'Masculino' },
                { label: 'Prefiro não informar', value: 'Indiferente' },
              ]}
              value={value}
              onChange={onChange}
              vertical={theme.isMobile}
              error={error?.message ?? ''}
              label="Sexo biológico"
            />
          </>
        )}
      />
      <MobileAutoSpace />
      <View className="mb-24 mt-16 w-full items-center self-center text-center mobile:mb-0">
        <Button
          disabled={loading || !isValid}
          variant="primary"
          onPress={handleSubmit(handleForm)}
          loading={loading}>
          Atualizar
        </Button>
      </View>
    </>
  );
};

export default UpdateProfileDataForm;
