import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/@hello-ui';

interface BellaIntegrationHealthProps {
  uri: string | null;
  onMessage: (message: string) => void;
}

export const BellaIntegrationHealth = ({ uri, onMessage }: BellaIntegrationHealthProps) => {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);

  if (Platform.OS === 'web') {
    window.addEventListener('message', (event) => {
      if (event.origin === 'https://tem-mulher.temsaude.com/') {
        onMessage && onMessage(event?.data);
      }
    });
  }

  const className = theme.isDesktop ? 'h-full min-h-[590px]' : 'h-screen';

  return (
    <View className={className}>
      {!uri || loading ? (
        <Loader variant="circle-loader" />
      ) : Platform.OS === 'web' ? (
        <iframe
          src={uri}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-modals"
          style={{ width: '100%', height: '80vh', border: 'none' }}
        />
      ) : (
        <WebView
          source={{ uri }}
          onLoad={() => setLoading(true)}
          onLoadEnd={() => setLoading(false)}
          javaScriptEnabled
          sharedCookiesEnable
          thirdPartyCookiesEnabled
          originWhitelist={['*']}
          mixedContentMode="always"
          domStorageEnabled={true}
          allowFileAccess={true}
          scalesPageToFit={true}
          onMessage={(e) => {
            onMessage && onMessage(e?.nativeEvent?.data);
          }}
          allowUniversalAccessFromFileURLs={true}
        />
      )}
    </View>
  );
};
