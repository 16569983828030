import {
  ICheckDeepLinkFirstAccessRes,
  IValidateDeepLinkRes,
} from '~/services/api/olimpo/interfaces/deeplink-domain';
import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';

export class DeepLinkDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async validateDeepLink(code: string): Promise<IValidateDeepLinkRes> {
    const { data } = await this.olimpoService.axios.get<IValidateDeepLinkRes>(
      '/v1/hello/validate_deep_link',
      {
        params: {
          code,
        },
      },
    );

    return {
      cpf: data.cpf,
      feature: data.feature,
      programCode: data.programCode,
      showFooter: data.showFooter,
      showHeader: data.showHeader,
      showSummary: data.showSummary,
      tokenZeus: data.tokenZeus,
      refreshToken: data.refreshToken,
      claimNumber: data.claimNumber,
      patientCpf: data.patientCpf,
    };
  }

  async checkDeepLinkFirstAccess(cpf: string): Promise<ICheckDeepLinkFirstAccessRes> {
    const { data } = await this.olimpoService.axios.get<ICheckDeepLinkFirstAccessRes>(
      '/v1/hello/sso/first-access',
      {
        params: {
          cpf: cpf.replace(/\D/g, ''),
        },
      },
    );

    return {
      firstAccess: data.firstAccess,
    };
  }

  async completeDeepLinkFirstAccess(cpf: string, onix: string): Promise<void> {
    await this.olimpoService.axios.post('/v1/hello/sso/first-access', {
      cpf: cpf.replace(/\D/g, ''),
      onix,
    });
  }
}
