import { Platform } from 'react-native';
import { useShallow } from 'zustand/react/shallow';
import { PropsWithChildren } from 'react';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { SETTINGS_BY_FEATURE } from '~/auth/strategies/deep-link/constants/settings-by-feature';
import { useDeepLinkStore } from '~/auth/strategies/deep-link/deep-link.store';

export class DeepLink {
  static getParams() {
    return useDeepLinkStore.getState();
  }

  static useParams() {
    return useDeepLinkStore(
      useShallow((st) => ({
        ...st,
      })),
    );
  }

  static sendEvent(event: DeepLinkEvent) {
    if (Platform.OS === 'web') {
      window.parent.postMessage(event, 'https://temsaude.com');
    }
  }

  static getSettings(feature: keyof typeof SETTINGS_BY_FEATURE) {
    if (!feature) {
      return { allowedRoutes: [], params: {} };
    }

    return SETTINGS_BY_FEATURE[feature];
  }
}

export const DeepLinkOnly: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDeepLink } = DeepLink.useParams();

  if (!isDeepLink) {
    return null;
  }

  return children;
};

export const DeepLinkExcept: React.FC<PropsWithChildren> = ({ children }) => {
  const { isDeepLink } = DeepLink.useParams();

  if (isDeepLink) {
    return null;
  }

  return children;
};

export const DeepLinkFeature: React.FC<
  PropsWithChildren<{ features: (keyof typeof SETTINGS_BY_FEATURE)[] }>
> = ({ features, children }) => {
  const { feature: currentFeature } = DeepLink.useParams();

  if (!features.includes(currentFeature)) {
    return null;
  }

  return children;
};
