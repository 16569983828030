import React, { useCallback, useState } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { ListItem, PharmaciesToMapLocationsDTO } from '~/components/MapViewList/MapViewListDTO';
import { useApi } from '~/hooks/api';
import { Pharmacy, SearchTemFarmaPayload } from '~/contexts/types';
import { Location, SelectLocal } from '~/components/SelectLocal';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { MapViewList } from '~/components/MapViewList';
import { useSweetAlert } from '~/components/@hello-ui/SweetAlert';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { VIDALINK_FEATURES } from '~/helpers/features/discount-medicines/vidalink';
import { EPHARMA_FEATURES } from '~/helpers/features/discount-medicines/epharma';
import { RDUNIVERS_FEATURES } from '~/helpers/features/discount-medicines/temfarma';
import { useAuth } from '~/auth/legacy/useAuth';

enum SearchScreen {
  SelectLocal = 'select-local',
  Results = 'results',
}

export const BenefitsDiscountMedicinesSearch = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState(SearchScreen.SelectLocal);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [providers, setProviders] = useState<string | undefined>(undefined);
  const [currentCoordinates, setCurrentCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [drugstores, setDrugstores] = useState<ListItem<Pharmacy>[]>([]);
  const [nextPage, setNextPage] = useState<number | undefined>(undefined);

  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { overrideBodyMaxWidth, customPadding, scrollDisabled } = useStepper();

  const api = useApi();
  const { checkIsEnabledFeature } = useAuth();

  const checkVidalink = checkIsEnabledFeature(VIDALINK_FEATURES);
  const checkEpharma = checkIsEnabledFeature(EPHARMA_FEATURES);
  const checkRdUnivers = checkIsEnabledFeature(RDUNIVERS_FEATURES);

  const navigation = useNavigation();
  const theme = useTheme();

  const checkAvailableProviders = () => {
    const providersList = new Set<string>();

    if (checkVidalink) providersList.add('Vidalink');
    if (checkEpharma) providersList.add('Epharma');
    if (checkRdUnivers) providersList.add('Rdunivers');

    setProviders(Array.from(providersList).join(','));
  };

  const resetValues = () => {
    setLoading(false);
    setScreen(SearchScreen.SelectLocal);
    setDrugstores([]);
  };

  useFocusEffect(
    useCallback(() => {
      resetValues();
      checkAvailableProviders();
    }, []),
  );

  const pageSize = theme.isDesktop ? 20 : 8;

  const handleNextPharmacyPage = async () => {
    setLoading(true);

    const payload: SearchTemFarmaPayload = {
      latitude: currentCoordinates?.lat,
      longitude: currentCoordinates?.lng,
      provider_name: providers,
      page: nextPage,
      page_size: pageSize,
    };

    api
      .searchTemFarma(payload)
      .then(({ data }) => {
        if (data.total <= 0) {
          return setNextPage(undefined);
        }

        setDrugstores((prev) => [...prev, ...PharmaciesToMapLocationsDTO(data)]);
        setNextPage((prev) => (prev ? prev + 1 : undefined));
      })
      .catch(() =>
        showSweetAlert(
          'Erro ao buscar farmácias',
          'Nenhuma farmácia foi encontrada na sua região.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            buttons: [
              {
                variant: 'primary',
                text: 'Ok',
                onPress: () => {
                  hideSweetAlert();
                },
              },
            ],
          },
        ),
      )
      .finally(() => setLoading(false));
  };

  const handleConfirmLocation = async (location: Location) => {
    setLoading(true);

    try {
      if (!providers) throw new Error();

      const { data } = await api.searchZipCode(
        `${location.neighborhood[0].title}, ${location.city.title}, ${location.state.initials}`,
      );

      const { lat, lng } = data;

      const payload: SearchTemFarmaPayload = {
        latitude: lat,
        longitude: lng,
        provider_name: providers,
        page_size: pageSize,
      };

      await api.searchTemFarma(payload).then(({ data }) => {
        if (data.total <= 0) {
          return showSweetAlert(
            'Erro ao buscar farmácias',
            'Nenhuma farmácia foi encontrada na sua região.',
            'error',
            false,
            false,
            {
              layout: 'helloUi',
              buttons: [
                {
                  variant: 'primary',
                  text: 'Ok',
                  onPress: () => {
                    hideSweetAlert();
                  },
                },
              ],
            },
          );
        }

        setDrugstores(PharmaciesToMapLocationsDTO(data));
        setNextPage(2);
        setCurrentLocation(location);
        setCurrentCoordinates({ lat, lng });
        setScreen(SearchScreen.Results);
      });
    } catch {
      showSweetAlert(
        'Erro ao buscar farmácias',
        'Não foi possível buscar as farmácias próximas. Tente novamente mais tarde.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          buttons: [
            {
              variant: 'primary',
              text: 'Ok',
              onPress: () => {
                hideSweetAlert();
              },
            },
          ],
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    if (screen === SearchScreen.Results) {
      resetValues();
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('BenefitsDiscountMedicines');
    }
  };

  const title = `${currentLocation?.neighborhood[0].title}, ${currentLocation?.city.title} - ${currentLocation?.state.initials}`;

  const showNextPageButton = nextPage !== undefined;

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Buscar Farmácias',
        onBack: handleGoBack,
      }}
      overrideScrollEnabled={!scrollDisabled}
      overrideBodyMaxWidth={overrideBodyMaxWidth}
      overridePadding={customPadding}>
      {screen === SearchScreen.SelectLocal && (
        <SelectLocal
          title="Localização desejada"
          subtitle="Preencha automaticamente através do botão abaixo ou selecione manualmente"
          onConfirmedLocation={handleConfirmLocation}
          textButton="Buscar"
          buttonLoading={loading}
        />
      )}
      {screen === SearchScreen.Results && (
        <MapViewList
          title={title}
          data={drugstores}
          loading={loading}
          hasButton={false}
          showNextPageButton={showNextPageButton}
          handleNextPage={handleNextPharmacyPage}
          mapId="pharmacy-map"
        />
      )}
    </PageWithCard>
  );
};
