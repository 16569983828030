import React, { useCallback, useRef } from 'react';
import { useTheme } from 'styled-components/native';
import { ListRenderItemInfo, Platform, SectionList, View, ViewStyle } from 'react-native';
import { Loader } from '../Loader/Loader';
import { ListingProps } from './types';
import * as S from './styles';
import { ToTopButton } from './components/toTop';
import { Button, Typography } from '~/components/@hello-ui';
import { useWindowSize } from '~/hooks/useWindowSize';
import FlatIconArrowUp from '~/components/Icons/ArrowUp';
import FlatIconAngleSmallDown from '~/components/Icons/AngleSmallDown';

export const SectionListing = ({
  data,
  renderItem,
  contentStyle,
  listHeaderComponent,
  loading = false,
  twoColumns = false,
  hideBackToTop = false,
  fixedBackToTop = false,
  useSeparator = false,
  separatorColor,
  extraHeight = 0,
  containerStyle,
  keyExtractor,
  style,
  horizontal,
  heightValForCalc = 74,
  testID,
  showNextPageButton = false,
  handleNextPage,
}: ListingProps): JSX.Element => {
  const sectionListRef = useRef<SectionList>(null);
  const { isMobile, isDesktop, ...theme } = useTheme();
  const windowSize = useWindowSize();

  // TODO: refactor to dynamically grow
  const containerHeight = useCallback(() => {
    return Platform?.OS === 'web'
      ? windowSize.height - (isDesktop ? 420 : isMobile ? 230 : 320) + extraHeight
      : windowSize.height - 310 + extraHeight;
  }, [data]);

  const defaultKeyExtractor = useCallback((_item: any, idx: number) => {
    return idx.toString();
  }, []);

  const columnsCount = twoColumns && windowSize.width >= 1290 ? 2 : 1;

  const hasScroll = () => {
    const maxRows = Math.round(containerHeight() / heightValForCalc);
    const rows = data?.length / columnsCount;
    return rows >= maxRows;
  };

  const renderItemWithSeparator = (info: ListRenderItemInfo<any>) => {
    return (
      <View className="bg-gray-100 mt-4 px-3 rounded-md">
        {useSeparator && <S.Divider color={separatorColor} />}
        {renderItem(info, { columnsCount })}
        {useSeparator && info.index === data.length - 1 && <S.Divider color={separatorColor} />}
      </View>
    );
  };

  return (
    <>
      {loading && (!handleNextPage || data.length === 0) ? (
        <Loader variant="circle-loader" />
      ) : (
        <>
          <View
            className={'mt-[-16px] h-[100px] flex-grow basis-auto'}
            style={{ ...(containerStyle as ViewStyle) }}>
            <SectionList
              testID={testID ?? 'list-test'}
              key={columnsCount}
              sections={data}
              horizontal={horizontal ?? false}
              contentContainerStyle={{ flexGrow: 1, ...(contentStyle as ViewStyle) }}
              ref={sectionListRef}
              style={style}
              keyExtractor={keyExtractor ?? defaultKeyExtractor}
              renderItem={renderItemWithSeparator}
              stickySectionHeadersEnabled={false}
              renderSectionHeader={({ section: { title } }) => (
                <Typography
                  variant="body1"
                  className="ml-2 mt-16 font-bold"
                  color={theme.colors.black}>
                  {title}
                </Typography>
              )}
              ListHeaderComponent={listHeaderComponent}
              ListFooterComponent={
                !hideBackToTop && !fixedBackToTop && !loading && !!data?.length && hasScroll() ? (
                  <Button
                    variant="outline"
                    className="mt-16"
                    icon={<FlatIconArrowUp size={16} color={theme.colors.primary} />}
                    onPress={() => {
                      sectionListRef.current?.scrollToLocation({
                        sectionIndex: 0,
                        itemIndex: 0,
                        animated: true,
                      });
                    }}>
                    Subir para o topo
                  </Button>
                ) : showNextPageButton ? (
                  <Button
                    loading={loading}
                    disabled={loading}
                    className="mx-auto my-16 max-w-[530px]"
                    variant="outline"
                    iconPosition="right"
                    icon={<FlatIconAngleSmallDown size={24} color={theme.colors.primary} />}
                    onPress={handleNextPage}>
                    Ver mais
                  </Button>
                ) : (
                  <></>
                )
              }
            />
          </View>
        </>
      )}
    </>
  );
};
