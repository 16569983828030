import React from 'react';
import { Linking, Platform, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Feather from 'react-native-vector-icons/dist/Feather';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/benefitsCheckup/desktop.png';
import mobileBackground from '../../assets/images/benefitsCheckup/mobile.png';
import { ContactCentral } from './components/ContactCentral';
import { AutoCheckup } from './components/AutoCheckup';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { useAuth } from '~/auth/legacy/useAuth';
import { Can, Cant } from '~/components/CanComponent';
import { isItauOnix } from '~/helpers/itau';

export const BenefitsCheckup = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();

  const { checkIsEnabledFeature } = useAuth();

  const checkAutoCheckup = checkIsEnabledFeature('checkup_auto');

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <Feather name="heart" size={22} color="white" />,
        title: 'Check-Up Médico',
        onBack: () => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.navigate('ScheduleAppointment');
          }
        },
      }}>
      <View>
        <Typography variant="body2">
          O Check-Up Médico é a melhor{' '}
          <Typography variant="bodyHighlight2">
            medida preventiva para um diagnóstico rápido e completo de sua saúde.
          </Typography>{' '}
          Através de consultas e exames regulares, podemos manter e melhorar nossa qualidade de
          vida.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <View className="mb-16 gap-y-8">
            <Typography variant="body2">
              O Check-Up Médico é um pacote completo de cuidados com sua saúde, nele são inclusos:
            </Typography>

            <View className="flex-row gap-x-8 pl-8">
              <Typography variant="body2">•</Typography>
              <Typography variant="body2">Primeira consulta;</Typography>
            </View>
            <View className="flex-row gap-x-8 pl-8">
              <Typography variant="body2">•</Typography>
              <Typography variant="body2">Lista de exames;</Typography>
            </View>
            <View className="flex-row gap-x-8 pl-8">
              <Typography variant="body2">•</Typography>
              <Typography variant="body2">Consulta de retorno.</Typography>
            </View>

            <Typography variant="body2">
              Confira no regulamento os exames inclusos, a carência e o limite de utilização do
              benefício.
            </Typography>
          </View>

          <Button
            variant="link"
            className="justify-start p-0"
            textStyle={{ marginLeft: -10 }}
            icon={<Icon name="chevron-right" size={24} color={theme.colors.primary} />}
            onPress={() => Linking.openURL('https://mkt.dd.meutem.com.br/wp-content/uploads/2021/02/RegulamentoTEM-Registrado.pdf')}
            iconPosition="right">
            Regulamento
          </Button>
        </Collapse>
      </View>
      <MobileAutoSpace />

      {checkAutoCheckup ? <AutoCheckup /> : <ContactCentral />}
    </PageWithCard>
  );
};