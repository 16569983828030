export enum EListType {
  Next,
  History,
}

export interface AppointmentProps {
  finished: boolean;
}

export interface StepTypes {
  disabled?: boolean;
}
