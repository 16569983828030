import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';
import { useAuth } from '~/auth/legacy/useAuth';
import CircleGroup from '~/components/Icons/CircleGroup';
import HospitalAdmisionIcon from '~/components/Icons/HospitalAdmisionIcon';

export const BenefitsInsurance = (): JSX.Element => {
  const navigation = useNavigation();

  const { checkIsEnabledFeature } = useAuth();

  const cards = useMemo(
    () =>
      [
        {
          icon: <CircleGroup width={24} height={24} />,
          title: 'Morte e Invalidez por Acidente',
          description: 'Indenização em caso de morte ou invalidez total permanente',
          onPress: () => navigation.navigate('BenefitsInsuranceDeathDisability'),
          visible: checkIsEnabledFeature('life_insurance'),
          iconSideOfTitle: true,
        },
        {
          icon: <CircleGroup width={24} height={24} />,
          title: 'Assistência Funeral',
          description: 'Apoio em momentos sensíveis',
          onPress: () => navigation.navigate('BenefitsFunerals'),
          visible: checkIsEnabledFeature(['funeral', 'funeral_memorial', 'funeral_igs']),
          iconSideOfTitle: true,
        },
        {
          icon: <HospitalAdmisionIcon width={24} height={24} />,
          title: 'Diária por Internação Hospitalar',
          description: 'Apoio Indenização por internação',
          onPress: () => navigation.navigate('BenefitsHospitalAdmision'),
          visible: checkIsEnabledFeature(['dih']),
          iconSideOfTitle: true,
        }
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Coberturas de Seguros',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
