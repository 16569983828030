import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiBaseConfig } from '~/services/api/interfaces/api-base-config';
import { AuthService } from '~/auth/auth-service';
import { IWaitRefreshToken } from '~/services/api/interfaces/wait-refresh-token';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';

export class ApiBase {
  axios: AxiosInstance;

  static isRefreshingToken = false;
  static WAIT_FOR_REFRESH_TOKEN_TIMEOUT = 10000;
  static WAIT_FOR_REFRESH_TOKEN_INTERVAL = 100;

  static waitIfRefreshingToken(): Promise<IWaitRefreshToken> {
    const maxWaits = this.WAIT_FOR_REFRESH_TOKEN_TIMEOUT / this.WAIT_FOR_REFRESH_TOKEN_INTERVAL;
    let waits = 0;

    return new Promise((resolve) => {
      const wasRefreshing = this.isRefreshingToken;
      const interval = setInterval(() => {
        if (waits > maxWaits) {
          clearInterval(interval);
          resolve({ wasRefreshing, timeout: true });
        }

        if (!this.isRefreshingToken) {
          clearInterval(interval);
          resolve({ wasRefreshing, timeout: false });
        }

        waits++;
      }, this.WAIT_FOR_REFRESH_TOKEN_INTERVAL);
    });
  }

  constructor({ baseUrl }: ApiBaseConfig) {
    this.axios = axios.create({ baseURL: baseUrl });
    this.applyAddTokenToRequest();
    this.applyRefreshTokenLogic();
    this.applyDeepLinkCodeToRequest();
  }

  private applyAddTokenToRequest() {
    this.axios.interceptors.request.use(async (req: AxiosRequestConfig) => {
      const token = await AuthService.getToken();

      if (token) {
        req.headers.token = token;
        req.headers.tokenzeus = token;
      }

      return req;
    });
  }

  private applyDeepLinkCodeToRequest() {
    this.axios.interceptors.request.use(async (req: AxiosRequestConfig) => {
      const deepLinkCode = DeepLink.getParams().deepLinkCode;

      if (deepLinkCode) {
        req.headers['x-deeplink-code'] = deepLinkCode;
      }

      return req;
    });
  }

  private applyRefreshTokenLogic() {
    this.axios.interceptors.response.use(
      (res) => res,
      async (error: Record<string, unknown>) => {
        if (this.isAxiosError(error) && error.response.status === 401) {
          const request = error.config;

          if (request._retry) {
            await AuthService.onTokenExpire();
            return Promise.reject(error);
          }

          request._retry = true;
          return this.tryToRefreshToken(error, request);
        }

        return Promise.reject(error);
      },
    );
  }

  private static refreshTokenPromise: Promise<string | null> | null = null;

  private async tryToRefreshToken(error: unknown, request: AxiosRequestConfig) {
    if (!ApiBase.refreshTokenPromise) {
      ApiBase.isRefreshingToken = true;

      ApiBase.refreshTokenPromise = AuthService.onBeforeTokenExpire()
        .then(({ token }) => {
          ApiBase.isRefreshingToken = false;
          ApiBase.refreshTokenPromise = null;
          return token;
        })
        .catch(async (err) => {
          ApiBase.isRefreshingToken = false;
          ApiBase.refreshTokenPromise = null;
          await AuthService.onTokenExpire();
          throw err;
        });
    }

    try {
      const token = await ApiBase.refreshTokenPromise;
      if (token) {
        const headers = request.headers as { token: string };
        headers.token = token;
        return this.axios.request(request);
      }
    } catch {
      await AuthService.onTokenExpire();
      return Promise.reject(error);
    }
  }

  private async retryRequestWithNewToken(error: unknown, request: AxiosRequestConfig) {
    const headers = request.headers as { token: string };
    const token = await AuthService.getToken();

    if (token) {
      headers.token = token;
      return this.axios.request(request);
    }

    return Promise.reject(error);
  }

  isAxiosError(
    error: Record<string, unknown>,
  ): error is { config: AxiosRequestConfig; response: AxiosResponse } {
    return 'response' in error;
  }
}
