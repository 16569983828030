import cardValidator from 'card-validator';

export const isDateValid = (number: string): boolean => {
  if (cardValidator.expirationDate(number).isValid) {
    return true;
  }

  return false;
};

export const isBirthdateValid = (number: string): boolean => {
  const [day, month, year] = number.split('/');
  const validDate = new Date(Number(year), Number(month - 1), Number(day));
  const currentDate = new Date();

  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  if (validDate <= currentDate) return true;

  return false;
};

export const isBrandValid = (number: string): string => {
  return cardValidator.number(number).card?.type.toUpperCase();
};

export const validatePhone = (phone: string): boolean => {
  const ddd = [
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '21',
    '22',
    '24',
    '27',
    '28',
    '31',
    '32',
    '33',
    '34',
    '35',
    '37',
    '38',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '51',
    '53',
    '54',
    '55',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '71',
    '73',
    '74',
    '75',
    '77',
    '79',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
  ];
  const clean = phone.replace(/[().\s-]/g, '');
  const phonePrefix = clean.substring(0, 2);
  if (!ddd.includes(phonePrefix)) {
    return false;
  }

  const telephone = /^(?!([0-9])\1{7,}).{8,}$/;
  telephone.test(clean);

  if (telephone.test(clean)) {
    const regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
    return regex.test(clean);
  } else {
    return false;
  }
};
