import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { StepTypes } from './types';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import { Appointment } from '~/screens/MyAppointments/state/types';
import { historyStatus } from './AppointmentDetails/AppointmentDetails';
export const Wrapper = styled.View`
  max-width: 600px;

  align-items: center;
`;

export const WrapperList = styled.FlatList`
  max-width: 600px;
  width: 100%;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black16};
  width: 100%;
  height: 0.5px;
  margin-bottom: 10px;
`;

export const Container = styled(Wrapper)`
  padding-top: 0;
`;

export const WrapperHeader = styled.View`
  background-color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
  padding-top: 48px;
  padding-bottom: 64px;
  margin-bottom: 124px;
`;

export const WrapperHeaderContainer = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
  `}
`;

export const ContainerViewCard = styled.View`
  width: 100%;
  max-width: 570px;
  padding: 0 0 15px 0;
  z-index: 9999;
  padding-left: 25px;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding: 0 10px 80px 10px;
  `}
`;

export const ContainerCard = styled.TouchableOpacity`
  width: 100%;
  z-index: 9999;
  justify-content: center;
`;

export const WrapperCard = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile
      ? `shadow-color: #000;
      shadow-offset: ${Platform.OS === 'ios' ? '{width: -1, height: -2}' : '{width: 1, height: 2}'};
      shadow-opacity: 0.15;
    shadow-radius: ${Platform.OS === 'ios' ? '7px' : '30px'};
    elevation: ${Platform.OS === 'ios' ? 1 : 8};`
      : 'box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15)'};
  flex-direction: column;
  padding-top: 16px;
`;

export const WrapperCardDetailHeader = styled.View`
  background-color: ${({ theme: { colors } }) => colors.white};
  flex-direction: column;
  padding-top: 16px;
`;

export const WrapperCardContent = styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  margin-bottom: 8px;
`;

export const WrapperCardDetailContent = styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  margin-bottom: 16px;
  ${({ theme: { isMobile } }) =>
    !isMobile &&
    `
    padding-left: 130px;
  `}
`;

export const WrapperCardHeader = styled.View`
  flex: 1;
  justify-content: center;
`;

export const WrapperCardIcon = styled.View<{ status?: Appointment['status'] }>`
  background-color: ${({ theme: { colors }, status }) =>
    ['bmgSchedule', 'finished'].includes(status)
      ? colors.success
      : historyStatus.includes(status)
        ? colors.error
        : colors.primary};
  width: ${({ theme }) => (theme.isMobile ? 48 : 56)}px;
  height: ${({ theme }) => (theme.isMobile ? 48 : 56)}px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) =>
    theme.isMobile ? theme.tokens.space.s16 : theme.tokens.space.s24};
`;

export const WrapperCardBlueIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.blue};
  width: 56px;
  height: 56px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

export const WrapperCardActions = styled.View`
  flex-direction: row;
  padding: 0 16px 16px;
  gap: 16px;
`;

export const Action = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-color: ${({ theme: { colors } }) => colors.black16};
`;

export const ActionLeft = styled(Action)`
  border-right-width: 0.25px;
  border: 0px solid ${({ theme: { colors } }) => colors.white};
`;

export const Status = styled(Action)`
  background-color: ${({ theme: { colors } }) => colors['background-gray']};
  border-radius: 8px;
  padding: 15px;
  width: 95%;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding: 10px;
  `}
`;

export const WrapperCards = styled.View`
  flex-direction: row;
  margin-horizontal: -12px;
  margin-top: 40px;
  margin-bottom: 30px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
    margin-top: 70px;
    flex-direction: row;
    padding-horizontal: 16px;
    margin-bottom: 40px;
  `
      : `
    margin-horizontal: -12px;
  `}
`;

export const CardItem = styled.TouchableOpacity`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  max-width: 288px;
  height: 118px;
  border-radius: 10px;
  padding: 16px;

  ${({ theme: { isMobile } }) =>
    isMobile
      ? `
      margin-horizontal: 12px;
      height: 128px;
      max-width: 132px;
      margin-horizontal: 8px;
      shadow-color: #000;
      shadow-offset: {width: 1, height: 1};
      shadow-opacity: 0.15;
      shadow-radius: ${Platform.OS === 'android' ? '30px' : '4px'};
      elevation: 6;
  `
      : `
    margin-horizontal: 12px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.15);
    elevation: 2;
  `}
`;

export const CardItemIcon = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: auto;
`;

export const WrapperStepsNextOrHistory = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 20px auto 24px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const WrapperSteps = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 20px auto 20px auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const WrapperStepsBar = styled.View`
  width: 100%;
  max-width: 510px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    max-width: 300px;
  `}
`;

export const WrapperStepsRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperStep = styled.TouchableOpacity<{ current: boolean }>`
  height: 30px;
  width: 300px;
  flex: 1;
  margin-horizontal: 7px;
  border-bottom-color: ${({ theme: { colors }, current }) =>
    !current ? colors.gray20 : colors.gray};
  border-bottom-width: 2px;
  text-align: center;
  align-items: center;
  font-weight: ${({ current }) => (current ? '800' : '300')};
`;

export const Title = styled.Text<{ current: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({ current }) => (current ? '800' : '400')};
  width: 100%;
  text-align: center;
  align-items: center;
`;

export const IconItem = styled.View`
  background-color: ${({ theme: { colors } }) => colors.black5};
  width: 56px;
  height: 56px;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
`;

export const WrapperLoading = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled.ActivityIndicator.attrs({
  color: StyledDefaultTheme.colors.black,
})``;

export const WrapperControl = styled.View`
  width: 100%;
`;

export const WrapperControlLeftList = styled.View`
  position: absolute;
  flex-direction: column;
  align-self: flex-start;
  flex: 1;
`;

export const ControlListFirst = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  left: 0px;
`;

export const WrapperControlCenter = styled.View`
  flex-direction: column;
  align-self: center;
  flex: 2;
  margin-top: 30px;
  position: absolute;
`;

export const WrapperControlRightList = styled.View`
  position: absolute;
  flex-direction: column;
  align-self: flex-end;
  flex: 3;
`;

export const ControlListPrevious = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  left: 50px;
`;

export const ControlListNext = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  right: 50px;
`;

export const ControlListLast = styled.TouchableOpacity`
  position: absolute;
  width: 50px;
  height: 58px;
  top: 22px;
  padding: 0 10px 0 10px;
  right: 0px;
`;

export const Text = styled.Text``;

export const KeepTakingCare = styled.View`
  padding-top: 20px;
`;

export const KeepTakingCareTitle = styled.Text`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.black70};
  padding-bottom: 10px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
      padding: 10px 0px 10px 0px;
  `}
`;

export const ScheduleDetail = styled.View<{ display: boolean }>`
  padding-bottom: 15px;
  display: ${({ display }) => (display ? 'flex' : 'none')};
`;

export const DayPreference = styled.View`
  flex-direction: row;
`;

export const DayPreferenceDetail = styled.View`
  width: 50%;
  padding-bottom: 20px;
`;

export const StepsBar = styled.View<{ unloaded?: string }>`
  width: ${({ unloaded }) => unloaded ?? '100%'};
  height: 4px;
  position: absolute;
  top: 50%;
  z-index: -1;

  ${({ theme: { colors } }) =>
    `
    background-color: ${colors.blue};
    left: 0px;
  `}
`;

export const StepsBarBackground = styled.View<{ unloaded?: string }>`
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  z-index: -1;
  padding: 0 20px 0 20px;

  ${({ theme: { colors } }) =>
    `
    background-color: ${colors.gray20};
    left: 0px;
  `}
`;

export const WrapperStepBar = styled.View<StepTypes>`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.gray20 : colors.blue};
  width: 52px;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  margin: 18px;
`;

export const WrapperStepBarRight = styled.View<StepTypes>`
  background-color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.gray20 : colors.blue};
  width: 52px;
  height: 52px;
  border-radius: 26px;
  align-items: center;
  justify-content: center;
  margin: 18px;
  margin-right: 83px;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-right: 25px;
`}
`;

export const Crads = styled.View`
  align-items: center;
  justify-content: center;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  margin-top: 20px;
`}
`;

export const WrapperContent = styled.View`
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
`;

export const TextCard = styled.Text`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.black70};
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    margin-top: 0px;
  `}
`;

export const ButtonWrapper = styled.View`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ModalWrapper = styled.View`
  text-align: center;
  ${({ theme: { isMobile } }) => !isMobile && 'display:flex'}
  flex-direction:column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  ${({ theme: { isMobile } }) => isMobile && 'height:100%'}
`;

export const ModalButtonsWrapper = styled.View`
  ${({ theme: { isMobile } }) => !isMobile && 'display:flex'}
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-direction: row;
  margin-top: 32px;
`;

export const ModalButtonWrapper = styled.View`
  width: 120px;
  height: 40px;
`;

export const ConfirmModalButtonWrapper = styled.View`
  height: 40px;
  width: 250px;
`;

export const WrapperAppointmentDetailsHeader = styled.View`
  ${({ theme: { isMobile } }) => !isMobile && 'display:flex'}
  width: 100%;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const WrapperAppointmentDetailsHeaderNext = styled.View`
  flex-direction: row;
  align-items: center;
  ${({ theme: { isMobile } }) => !isMobile && 'display:flex'}
`;