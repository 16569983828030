import { createStep } from '../Progress';

import {
  ClipFile,
  Memo as MemoIcon,
  MemoCircleCheck,
  TimeCheck,
  Clock,
  TimeQuarterPast,
  CalendarCheck,
  CalendarDay,
  Calendar,
  CommentsQuestionCheck,
  CommentsQuestion,
  Comments,
} from '~/assets/icons/flaticon';

export const SendMedicalRequest = createStep({
  completedIcon: MemoCircleCheck,
  currentIcon: ClipFile,
  defaultIcon: MemoIcon,
});

export const Await = createStep({
  completedIcon: TimeCheck,
  currentIcon: Clock,
  defaultIcon: TimeQuarterPast,
});

export const Planned = createStep({
  completedIcon: CalendarCheck,
  currentIcon: CalendarDay,
  defaultIcon: Calendar,
});

export const Results = createStep({
  completedIcon: CommentsQuestionCheck,
  currentIcon: CommentsQuestion,
  defaultIcon: Comments,
});