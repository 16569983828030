import React, { useCallback } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { Linking } from 'react-native';
import { PaymentCard } from '../../components/@hello-ui/PaymentCard';
import * as S from './styles';
import { useTemPay } from '~/hooks/temPay';
import { useAuth } from '~/auth/legacy/useAuth';
import { Button, Collapse, Typography } from '~/components/@hello-ui';
import LogoEPharma from '~/assets/images/ePharma.png';
import LogoVidalink from '~/assets/images/vidalink.png';
import LogoUnivers from '~/assets/images/LogoUnivers.png';

import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Can } from '~/components/CanComponent';
import { DeepLink } from '~/auth/strategies/deep-link/deep-link-utils';
import { DeepLinkEvent } from '~/auth/strategies/deep-link/enums/deep-link-event';
import { MERCADO_PAGO_ONIX } from '~/helpers/mercado-pago';
import { VIDALINK_FEATURES } from '~/helpers/features/discount-medicines/vidalink';
import { EPHARMA_FEATURES } from '~/helpers/features/discount-medicines/epharma';
import {
  DPSP_FEATURES,
  DROGAL_FEATURES,
  PAGMENOS_EXTRAFARMA_FEATURES,
  RDUNIVERS_FEATURES,
  TEMFARMA_FEATURES,
} from '~/helpers/features/discount-medicines/temfarma';

export const BenefitsDiscountMedicines = (): JSX.Element => {
  const { card, loadingCard } = useTemPay();
  const { user, checkIsEnabledFeature } = useAuth();
  const { isDeepLink } = DeepLink.useParams();
  const { onixCode } = useAuth();

  const vidalinkDiscount = (() => {
    if (checkIsEnabledFeature('desconto_medicamentos_vidalink_20%')) return 20;
    if (checkIsEnabledFeature('desconto_medicamentos_vidalink_30%')) return 30;

    return 0;
  })();

  const epharmaDiscount = (() => {
    if (checkIsEnabledFeature('desconto_medicamentos_epharma_20%')) return 20;
    if (checkIsEnabledFeature('desconto_medicamentos_epharma_30%')) return 30;

    return 0;
  })();

  const checkMercadoPago = onixCode
    ? MERCADO_PAGO_ONIX.includes(onixCode.onix_code.toString())
    : false;

  const navigation = useNavigation();

  const LogoAuthorizer: Record<string, JSX.Element> = {
    RDUnivers: <S.ImageAuthorizer source={LogoUnivers} width={190} />,
    'E-Pharma': <S.ImageAuthorizer source={LogoEPharma} width={166} />,
    Vidalink: <S.ImageAuthorizer source={LogoVidalink} width={220} />,
  };

  const goToHealthAccount = () => {
    Linking.openURL('https://pesquisamedicamento.epharma.com.br/?benefitId=101424');
  };

  const handleGoBack = useCallback(() => {
    if (isDeepLink) {
      DeepLink.sendEvent(DeepLinkEvent.Cancel);
    }

    if (navigation.canGoBack() && !isDeepLink && !checkMercadoPago) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: 'Dashboard',
            },
          ],
        }),
      );
    }
  }, [navigation]);

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Descontos em Farmácia',
        onBack: isDeepLink ? undefined : handleGoBack,
      }}>
      <View className="mb-8 mt-8 mobile:mb-0">
        <PaymentCard
          loading={loadingCard}
          name={user?.name}
          cardNumber={card?.numero_cartao.replace(/(\d{4})/g, '$1 ')}
          cardVia={card?.via}
        />
      </View>
      <Typography variant="body2" className="mb-24">
        <Typography variant="bodyHighlight2">Compre medicamentos com desconto</Typography> nas
        maiores farmácias do Brasil. Saiba como utilizar:
      </Typography>
      <Can features={EPHARMA_FEATURES}>
        <Collapse title="Epharma">
          <Typography variant="body2" className="mb-16">
            <Typography variant="bodyHighlight2">
              Garanta {epharmaDiscount}% de desconto*
            </Typography>{' '}
            na compra de qualquer medicamento nas farmácias credenciadas.
          </Typography>

          <Typography variant="bodyHighlight2" className="mb-8">
            Onde utilizar:
          </Typography>
          <Typography variant="body2" className="mb-16">
            Temos <Typography variant="bodyHighlight2">mais de 20 mil unidades</Typography>{' '}
            disponíveis em todo o território nacional.{' '}
            <Typography variant="link" onPress={() => goToHealthAccount()}>
              Clique aqui
            </Typography>{' '}
            e encontre a <Typography variant="bodyHighlight2">farmácia</Typography> mais próxima de
            você.
          </Typography>

          <Typography variant="bodyHighlight2" className="mb-8">
            Como utilizar:
          </Typography>
          <Typography variant="body2" className="mb-16">
            <Typography variant="bodyHighlight2">Informe seu CPF</Typography> e o{' '}
            <Typography variant="bodyHighlight2">autorizador TEM Saúde Epharma</Typography> no
            balcão ou no caixa no momento do pagamento para validar seu desconto.
          </Typography>

          <S.WrapperImageAuthorizer>
            <Typography variant="helperText" className="mb-8">
              Autorizador de descontos:
            </Typography>
            {LogoAuthorizer['E-Pharma']}
          </S.WrapperImageAuthorizer>
          <Typography variant="helperText" className="mt-16">
            *Esse desconto é válido exclusivamente para itens classificados como medicamento pela
            ANVISA.
          </Typography>
        </Collapse>
      </Can>
      <Can features={TEMFARMA_FEATURES}>
        <Collapse title="TEM Farma">
          <Typography variant="body2" className="mb-16">
            <Typography variant="bodyHighlight2">A partir de 15% de desconto*</Typography> para
            compras de medicamentos de marca e genéricos nas principais redes de farmácia do Brasil.
          </Typography>

          <Typography variant="bodyHighlight2" className="mb-8">
            Onde utilizar:
          </Typography>
          <View className="mb-16 space-y-4 pl-8">
            <Can features={RDUNIVERS_FEATURES}>
              <Typography variant="body2">• Droga Raia</Typography>
              <Typography variant="body2">• Drogasil</Typography>
            </Can>
            <Can features={DPSP_FEATURES}>
              <Typography variant="body2">• Drogaria São Paulo</Typography>
              <Typography variant="body2">• Drogaria Pacheco</Typography>
            </Can>
            <Can features={PAGMENOS_EXTRAFARMA_FEATURES}>
              <Typography variant="body2">• Pague Menos</Typography>
              <Typography variant="body2">• Extrafarma</Typography>
            </Can>
            <Can features={DROGAL_FEATURES}>
              <Typography variant="body2">• Drogal</Typography>
            </Can>
          </View>

          <Typography variant="bodyHighlight2" className="mb-8">
            Compras presenciais:
          </Typography>
          <Typography variant="body2" className="mb-16">
            Informe seu <Typography variant="bodyHighlight2">CPF</Typography> no balcão ou no caixa
            no momento do pagamento para validar seu desconto.
          </Typography>

          <Can features={RDUNIVERS_FEATURES}>
            <Typography variant="bodyHighlight2" className="mb-8">
              Compras nos sites e aplicativos:
            </Typography>
            <Typography variant="body2" className="mb-16">
              Nos <Typography variant="bodyHighlight2">sites</Typography> e{' '}
              <Typography variant="bodyHighlight2">aplicativos</Typography> oficiais de cada
              farmácia, acesse a área MEUS BENEFÍCIOS, ative o desconto TEM Saúde e prossiga para
              suas compras.
            </Typography>
          </Can>

          <Can features={RDUNIVERS_FEATURES}>
            <Typography variant="bodyHighlight2" className="mb-8">
              Compras nas televendas:
            </Typography>
            <Typography variant="body2" className="mb-16">
              Informe seu <Typography variant="bodyHighlight2">CPF</Typography> ao atendente no
              momento da compra para validar seu desconto.
            </Typography>
          </Can>

          <Typography variant="helperText">
            *Desconto de 15% para medicamentos tarjados de marca e 35% para tarjados genéricos.
          </Typography>
        </Collapse>
      </Can>
      <Can features={VIDALINK_FEATURES}>
        <Collapse title="Vidalink">
          <Typography variant="body2" className="mb-16">
            <Typography variant="bodyHighlight2">
              No mínimo {vidalinkDiscount}% de desconto* garantido
            </Typography>{' '}
            na compra de qualquer medicamento nas farmácias credenciadas.
          </Typography>

          <Typography variant="bodyHighlight2" className="mb-8">
            Onde utilizar:
          </Typography>
          <Typography variant="body2" className="mb-16">
            Temos mais de{' '}
            <Typography variant="bodyHighlight2">20 mil farmácias credenciadas</Typography>{' '}
            disponíveis em todo o território nacional.
          </Typography>

          <Typography variant="bodyHighlight2" className="mb-8">
            Como utilizar:
          </Typography>
          <View className="mb-16 pl-8 mobile:space-y-4 desktop:space-y-8">
            <View className="flex-row">
              <Typography variant="body2" className="w-[14px] text-end">
                1.
              </Typography>
              <Typography variant="body2" className="ml-4 flex-1">
                No <Typography variant="bodyHighlight2">balcão de atendimento</Typography> informe
                que você tem desconto pelo{' '}
                <Typography variant="bodyHighlight2">autorizador da Vidalink</Typography>;
              </Typography>
            </View>
            <View className="flex-row">
              <Typography variant="body2" className="w-[14px] text-end">
                2.
              </Typography>
              <Typography variant="body2" className="ml-4 flex-1">
                Informe o número da <Typography variant="bodyHighlight2">Conta Saúde</Typography>,
                disponível no topo desta página, e{' '}
                <Typography variant="bodyHighlight2">data de nascimento</Typography>;
              </Typography>
            </View>
            <View className="flex-row">
              <Typography variant="body2" className="w-[14px] text-end">
                3.
              </Typography>
              <Typography variant="body2" className="ml-4 flex-1">
                Tudo certo! O balconista irá validar e aplicar seu desconto.
              </Typography>
            </View>
          </View>

          <S.WrapperImageAuthorizer>
            <Typography variant="helperText" className="mb-8">
              Autorizador de descontos:
            </Typography>
            {LogoAuthorizer['Vidalink']}
          </S.WrapperImageAuthorizer>
          <Typography variant="helperText" className="mt-16">
            * Esse desconto é válido exclusivamente para itens classificados como medicamento pela
            ANVISA.
          </Typography>
        </Collapse>
      </Can>
      <Button
        variant="primary"
        onPress={() => navigation.navigate('BenefitsDiscountMedicinesSearch')}>
        Buscar Farmácias
      </Button>
    </PageWithCard>
  );
};
