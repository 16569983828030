import React from 'react';
import { CanProps } from './types';
import { useAuth } from '~/auth/legacy/useAuth';

export const Can = ({ children, features, onix }: CanProps) => {
  const { checkIsEnabledFeature, onixCode } = useAuth();

  if (!onixCode) {
    return null;
  }

  let userCanSeeComponent: boolean = false;

  userCanSeeComponent = features ? checkIsEnabledFeature(features) : userCanSeeComponent;

  if (Array.isArray(onix) && onix.length > 0) {
    userCanSeeComponent = onix.includes(onixCode.onix_code);
  }

  if (typeof onix === 'string') {
    userCanSeeComponent = onix === onixCode.onix_code;
  }

  if (!userCanSeeComponent) return null;

  return <>{children}</>;
};
