import React, { useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { CardList } from '~/components/@hello-ui/CardList';

import Wellbeing from '~/components/Icons/Wellbeing';
import Health from '~/components/Icons/Health';
import Psychology from '~/components/Icons/Psychology';
import Nutrition from '~/components/Icons/Nutrition';
import MentalHealthIcon from '~/components/Icons/MentalHealth';
import HeadSideMedicalIcon from '~/components/Icons/HeadSideMedical';

import { useAuth } from '~/auth/legacy/useAuth';

import TrainingIcon from '~/assets/icons/muscle.svg';

export const BenefitsWellBeing = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();

  const { checkIsEnabledFeature, partner } = useAuth();

  const isNamuWebSSOEnabled =
    checkIsEnabledFeature('Namu_WebSSO') &&
    partner.features.find((feature) => feature.name === 'Namu_WebSSO')?.product_feature_active ===
      1;

  const isNamuEnabled =
    checkIsEnabledFeature('Namu') &&
    partner.features.find((feature) => feature.name === 'Namu')?.product_feature_active === 1;

  const linkManu = isNamuWebSSOEnabled ? 'BenefitsDigitalWellBeingSSO' : 'BenefitsDigitalWellBeing';

  const cards = useMemo(
    () =>
      [
        {
          icon: <Wellbeing />,
          title: 'Bem-Estar Digital',
          description: 'Cursos e conteúdos para cuidados com a saúde física, mental e nutricional',
          onPress: () => navigation.navigate(linkManu),
          visible: isNamuWebSSOEnabled || isNamuEnabled,
          iconSideOfTitle: true,
        },
        {
          icon: <Health />,
          title: 'Programa Hábitos Saudáveis',
          description: 'Consultas mensais com nutricionista, psicólogo e personal trainer',
          onPress: () => navigation.navigate('BenefitsHealthyHabitsProgram'),
          visible: checkIsEnabledFeature('medex_healthy_habits'),
          iconSideOfTitle: true,
        },
        {
          icon: <Psychology />,
          title: 'Orientação Psicológica',
          description: 'Para dúvidas e orientações sobre saúde mental por telefone',
          onPress: () => navigation.navigate('PsychologicalAssistance'),
          visible: checkIsEnabledFeature('psychological_teleorientation'),
          iconSideOfTitle: true,
        },
        {
          icon: <Nutrition />,
          title: 'Orientação Nutricional',
          description: 'Para dúvidas e orientações sobre nutrição por telefone',
          onPress: () => navigation.navigate('BenefitsNutritionalAssistance'),
          visible: checkIsEnabledFeature('nutritional_teleorientation'),
          iconSideOfTitle: true,
        },
        {
          icon: <MentalHealthIcon />,
          title: 'Programa de Saúde Mental',
          description: 'Consultas mensais com psicólogo',
          onPress: () => navigation.navigate('BenefitsMentalHealthProgram'),
          visible: checkIsEnabledFeature('medex_saude_mental'),
          iconSideOfTitle: true,
        },
        {
          icon: <HeadSideMedicalIcon />,
          title: 'Programa Mente e Corpo',
          description: 'Consultas mensais com nutricionista e psicólogo',
          onPress: () => navigation.navigate('BenefitsMindAndBodyProgram'),
          visible: checkIsEnabledFeature('programa_mente_e_corpo'),
          iconSideOfTitle: true,
        },
        {
          icon: <TrainingIcon fill={theme.colors.primary} height={24} width={24} />,
          title: 'Plataforma de Treinos',
          description: 'Treine onde e quando quiser!',
          onPress: () => navigation.navigate('BenefitsTraining'),
          visible: checkIsEnabledFeature('chicosalgado'),
          iconSideOfTitle: true,
        },
      ].filter(({ visible }) => visible),
    [],
  );

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Benefício Bem-Estar',
        onBack: () => {
          navigation.navigate('Dashboard');
        },
      }}>
      <CardList data={cards} />
    </PageWithCard>
  );
};
