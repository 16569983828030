import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Button, Typography, useSweetAlert } from '~/components/@hello-ui';

import { useAuth } from '~/auth/legacy/useAuth';
import { useApi } from '~/hooks/api';
import { useAppointment } from '~/hooks/appointment';
import { useTracking } from '~/services/tracking';

import { Schedule } from '~/@types/schedules';
import ActivityIndicator from '~/components/@tem-ui/ActivityIndicator';
import VideoCall24Icon from '~/components/Icons/VideoCall24Icon';
import { trackingEventType } from '~/contexts/tracking/types';

type QueueProps = {
  onCancel: () => void;
  onCall: (url: string) => void;
  dialogState: boolean;
};

const TIME_TO_REFETCH: number = 10000;

export const Queue = ({ onCall, onCancel, dialogState }: QueueProps): JSX.Element => {
  const api = useApi();
  const { user, onixCode: currentProduct } = useAuth();
  const { patient } = useAppointment();
  const { tracking } = useTracking();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [patientId, setPatientId] = useState<number | null>(null);
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

  const position = useMemo<number>(
    () => schedule?.appointment?.posicaoFila ?? 0,
    [schedule?.appointment?.posicaoFila],
  );

  const time = useMemo<number>(
    () => schedule?.appointment?.tempoEstimado ?? 0,
    [schedule?.appointment?.tempoEstimado],
  );

  const handleClearInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const onQueueCancel = useCallback(async () => {
    try {
      console.log('chamou');
      setCancelling(true);
      handleClearInterval();
      if (patientId) await api.telemedicine24hCancelQueue(patientId);
    } catch (error) {
      showSweetAlert('Ops, algo deu errado', 'Falha ao sair da fila.', 'error', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            testID: 'accept-button',
            variant: 'primary',
            onPress: () => hideSweetAlert(),
          },
        ],
      });
    } finally {
      setCancelling(false);
      onCancel();
    }
  }, [patient, schedule]);

  const getTelemedicine24hUrl = async () => {
    if (patientId && user) {
      const { data: data } = await api.telemedicine24hUrl(patientId);

      const schedulePayload = {
        appointment: {
          posicaoFila: data.data.queuePosition,
          tempoEstimado: data.data.estimatedRemainingTime,
        },
        call: {
          zoomMeetingUrlPatient: data.data.url,
        },
      };

      setSchedule({ ...(schedulePayload ?? null) });
    }
  };

  const startConsultation24h = async () => {
    if (user && currentProduct) {
      const { data: createCallResponse } = await api.telemedicine24h(
        user?.cpf.replace(/\D/g, ''),
        user?.birthdate,
        user?.name,
        currentProduct?.onix_code,
        patient?.cpf,
      );

      void tracking.addMovement({
        event: trackingEventType.ClickedOn24HVideoClickStart,
        metadata: {
          patient_info: patient,
          schedule_info: createCallResponse,
        },
      });

      setPatientId(createCallResponse.data.patientId);
    }
  };

  const retryDelay = 15000;
  const checkQueue = useCallback(async () => {
    let attempts = 0;
    const maxAttempts = 3;

    const startWithRetry = async () => {
      try {
        if (user && currentProduct && !patientId) {
          await startConsultation24h();
        }
      } catch (error) {
        if (error?.response?.status === 429) {
          if (attempts < maxAttempts) {
            attempts += 1;
            console.log(`Attempt ${attempts} failed. Retrying in 15 seconds...`);
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            await startWithRetry();
          } else {
            console.log('Max retry attempts reached.');
            await onQueueCancel();
          }
        }

        if (error?.response?.status === 469) {
          await onQueueCancel();
        }
      }
    };

    await startWithRetry();
  }, [user, patient, schedule, patientId]);

  useEffect(() => {
    void checkQueue();
  }, []);

  useEffect(() => {
    if (schedule?.call.zoomMeetingUrlPatient && intervalId) {
      const url: string = schedule.call.zoomMeetingUrlPatient;

      intervalId && clearTimeout(intervalId);
      setIntervalId(null);

      onCall(url);

      void tracking.addMovement({
        event: trackingEventType.Started24HVideo,
        metadata: {
          patient_info: patient,
          schedule_info: schedule,
        },
      });
    }
  }, [schedule, intervalId]);

  useEffect(() => {
    if (patientId) {
      const interval = setInterval(() => getTelemedicine24hUrl(), TIME_TO_REFETCH);
      setIntervalId(interval);
    }

    return () => handleClearInterval();
  }, [patientId]);

  useEffect(() => {
    if (dialogState && schedule?.call.zoomMeetingUrlPatient && intervalId) {
      void onQueueCancel();
    }
  }, [dialogState]);

  return (
    <View className="m-0 w-full items-center justify-center self-center pb-16 mobile:m-auto">
      <View className="mb-16 items-center justify-center">
        <VideoCall24Icon width={40} height={40} />
      </View>
      <View className="items-center text-center">
        <Typography variant="h3">Aguarde</Typography>
        <Typography variant="subtitle">Sua consulta será iniciada em breve</Typography>
      </View>
      <View className="my-24 h-[112px] w-full justify-center rounded-lg bg-background-gray p-24">
        {schedule && !cancelling ? (
          <Fragment>
            <Typography variant="body2">Sua posição na fila: {position}</Typography>
            <Typography variant="body2">Tempo médio de espera: {time} minutos</Typography>
          </Fragment>
        ) : (
          <ActivityIndicator />
        )}
      </View>
      <Button
        disabled={!schedule}
        variant="secondary"
        onPress={async () => {
          await onQueueCancel();
        }}>
        Cancelar
      </Button>
    </View>
  );
};
