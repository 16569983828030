import React from 'react';
import { Platform, View, StyleSheet, Linking } from 'react-native';
import { useNavigation, useTheme } from '@react-navigation/native';
import desktopBackground from '../../assets/images/surgicalProcedure/desk.png';
import mobileBackground from '../../assets/images/surgicalProcedure/mobile.png';
import * as S from './styles';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Typography, Button, useMedia, MobileAutoSpace } from '~/components/@hello-ui';
import { goBack } from '~/helpers';
import ScalpelIcon from '~/components/Icons/Scalpel';
import { Divider } from '~/components/@hello-ui/Divider';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { CallIcon } from '~/components/Icons/Call';
import { isPagBankOnix } from '~/helpers/pagbank';
import { useAuth } from '~/auth/legacy/useAuth';

export const SurgicalProcedure = (): JSX.Element => {
  const navigation = useNavigation();
  const theme = useTheme();
  const media = useMedia();
  const { onixCode } = useAuth();

  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;


  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <ScalpelIcon width={26} height={26} color="white" />,
        title: 'Procedimentos Cirúrgicos',
        onBack: () => {
          goBack(navigation, 'Dashboard');
        },
      }}>
      <View >
        <Typography variant="body2">
          Se precisar, você não fica na mão. Temos descontos e facilidades de parcelamento para procedimentos cirúrgicos.
        </Typography>

        <MobileAutoSpace heightMobile={100} heightDesktop={20}/>
        {Platform.OS === "web" && (
          <Divider />
        )}
        
        <View className={`${Platform.OS === "web" ? 'h-[60px]' : 'h-[70px]'} mb-24 w-full items-center justify-center self-center rounded-lg text-center ${Platform.OS === "web" && 'm-16'} ${Platform.OS !== "web" && 'mb-50'}`}>
          <Typography variant="bodyHighlight2" className='self-center text-center'>Para acionamento ou dúvidas, ligue para a Central de Atendimento:</Typography>
        </View>
       {media.isMobile ? (
        <View className={` h-[150px] w-full items-center justify-center self-center rounded-lg text-center ${Platform.OS !== 'web' ? 'mt-16' : 'mt-40'}`} >
          <View className='w-full items-center justify-center self-center rounded-lg text-center mb-16'>
          <Typography variant='description'>Para capitais e regiões metropolitanas</Typography>
               <Button
                 icon={<CallIcon color={theme.colors.background} />}
                 iconPosition="left"
                 variant="primary"
                 onPress={() => Linking.openURL(isPagBankOnix(onixCode?.onix_code) ? 'tel:1140031775' : 'tel:1140001640')}>
                 {isPagBankOnix(onixCode?.onix_code) ? '4003-1775' : '11 4000-1640'} 
               </Button>
          </View>
          <View className={` w-full items-center justify-center self-center rounded-lg text-center mb-16`}> 
            <Typography variant='body2'>Para demais regiões</Typography>
            <Button
              icon={<CallIcon color={theme.colors.notification} />}
              iconPosition="left"
              variant="outline"
              onPress={() => Linking.openURL(isPagBankOnix(onixCode?.onix_code) ? 'tel:08007282174' : 'tel:08008368836')}
            >
              {isPagBankOnix(onixCode?.onix_code) ? '0800 728 2174' : '0800 836 88 36'}
            </Button>
            
            
          </View>
          <View className='items-center justify-center self-center rounded-lg text-center'>
              <Typography variant="body1">Segunda à Sexta das 7h às 19h</Typography>
              <Typography variant="body1" style={{ fontSize: 12 }}>Exceto feriados nacionais</Typography>
            </View>
        </View>
             ) : (
               <View className="h-[150px] w-full flex flex-wrap flex-row rounded-lg bg-background-gray p-20">
                 <View className="mb-4 flex-column justify-start p-8 ">
                  <Typography variant='link' className='text-sm'>{isPagBankOnix(onixCode?.onix_code) ? '4003-1775' : '11 4000-1640'}</Typography>
                  <Typography variant="description" className='text-sm'>Capitais e regiões metropolitanas</Typography>
                 </View>
                 <View className="mb-4 flex-column justify-start p-8">
                  <Typography variant='link' className='text-sm'>{isPagBankOnix(onixCode?.onix_code) ? '0800 728 2174' : '0800 836 88 36'}</Typography>
                  <Typography variant="description" className='text-sm'>Demais localidades</Typography>
                 </View>
                 <View className="mb-4 flex-column justify-start p-8">
                  <Typography variant="body1" className='text-sm'>Segunda à Sexta das 7h às 19h</Typography>
                  <Typography variant="body1" style={{ fontSize: 12 }}>Exceto feriados nacionais</Typography>
                 </View>
               </View>
             )}
      </View>
      
    </PageWithCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  boldText: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  centeredContent: {
    alignItems: 'center',
  },
  additionalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  button: {
    marginTop: 20,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  icon: {
    marginRight: 8,
  },
});