import React, { useState } from 'react';

import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';

import desktopBackground from '../../assets/images/benefitsMindAndBodyProgram/desktop.png';
import mobileBackground from '../../assets/images/benefitsMindAndBodyProgram/mobile.png';

import { MedexWebView } from './components/MedexWebView';
import {
  Button,
  Collapse,
  Dialog,
  MobileAutoSpace,
  PageWithCard,
  Typography,
} from '~/components/@hello-ui';

import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';

import HeadSideMedicalIcon from '~/components/Icons/HeadSideMedical';
import { useAuth } from '~/hooks/auth';

export const BenefitsMindAndBodyProgram = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const backgroundImage = theme.isMobile ? mobileBackground : desktopBackground;

  const [dialogState, setDialogState] = useState<boolean>(false);

  const { user } = useAuth();

  return (
    <>
      <PageWithCard
        variant={PageWithCardVariant.FeaturedImage}
        featuredImageOptions={{
          backgroundImage,
          titleStartContent: <HeadSideMedicalIcon color={theme.colors.white} />,
          title: 'Programa Mente e Corpo',
          onBack: () => {
            navigation.goBack();
          },
        }}>
        <View>
          <Typography variant="body2">
            Receba apoio para realizar mudanças positivas em sua{' '}
            <Typography variant="bodyHighlight2">alimentação</Typography>,{' '}
            <Typography variant="bodyHighlight2">corpo</Typography> e{' '}
            <Typography variant="bodyHighlight2">mente</Typography>. Tenha acompanhamento de
            profissionais especializados para montar seu cardápio e cuidar da sua saúde mental.
          </Typography>
        </View>

        <View className="pt-24">
          <Collapse title="Saiba mais">
            <View className="mb-8 space-y-8">
              <Typography variant="body2">
                No Programa Mente e Corpo você realiza{' '}
                <Typography variant="bodyHighlight2">consultas mensais</Typography> com
                nutricionista e psicólogo e também pode tirar suas dúvidas do dia a dia na área de
                suporte na Central de Atendimento.
              </Typography>
            </View>
          </Collapse>
        </View>

        <MobileAutoSpace />

        <View className="mb-24 mt-40 w-full items-center self-center mobile:mb-16 mobile:mt-24">
          <Typography variant="bodyHighlight2" className="mx-auto max-w-[328px] text-center">
            Para utilizar o benefício, é só acessar o canal e solicitar o serviço:
          </Typography>
        </View>

        <Button variant="primary" onPress={() => setDialogState(true)} loading={false}>
          Acessar
        </Button>
      </PageWithCard>

      <Dialog visible={dialogState} onClose={() => setDialogState(false)}>
        <MedexWebView document={user?.cpf ?? ''} />
      </Dialog>
    </>
  );
};
