import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/native';
import { Typography, Input, Button, useDialog } from '~/components/@tem-ui';
import { Spacing } from '~/components/Spacing';

import { States } from '~/components/Dialog/States';
import { Cities } from '~/components/Dialog/Cities';
import { Neighborhoods } from '~/components/Dialog/Neighborhoods';
import { useApi } from '~/hooks/api';

interface Values {
  id: number;
  title: string;
  initials?: string;
}

const Wrapper = styled.View`
  width: 100%;
  max-width: 600px;
  margin: -10px auto 32px;
  flex: 1;
  justify-content: center;

  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
    padding: 0 16px;
    width: 100%;
  `}
`;

const WrapperForm = styled.View`
  flex-direction: column;
`;

const WrapperInput = styled.View`
  position: relative;
  min-height: 58px;
  margin-bottom: 16px;
`;

const InputField = styled.TouchableOpacity`
  height: 58px;
  top: 22px;
  margin-bottom: -58px;
`;

const WrapperButton = styled.View`
  width: 100%;
  max-width: 600px;
  margin: 24px auto 0;
`;

export const Search = ({ onLocalization }: any): JSX.Element => {
  const { openDialog, closeDialog } = useDialog();
  const api = useApi();

  const [loading, setLoading] = useState<boolean>(false);

  const [state, setState] = useState<Values | null>(null);
  const [city, setCity] = useState<Values | null>(null);
  const [neighborhood, setNeighborhood] = useState<Values | null>(null);

  const searchAddress = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.searchZipCode(
        `${neighborhood?.title}, ${city?.title}, ${state?.initials}`,
      );
      onLocalization({
        latitude: data.lat,
        longitude: data.lng,
        district: neighborhood,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [neighborhood, city, state]);

  useEffect(() => {
    closeDialog();
  }, [state, city, neighborhood]);

  return (
    <Wrapper>
      <Spacing bottom={10}>
        <Typography variant="heading1" color="black" bold>
          Qual a localização?
        </Typography>
      </Spacing>
      <WrapperForm>
        <WrapperInput>
          <InputField
            onPress={() => openDialog('Qual o estado?', <States onSelect={(s) => setState(s)} />)}
          />
          <Input editable={false} variant="light" label="Estado" value={state?.title} />
        </WrapperInput>
        <WrapperInput>
          <InputField
            onPress={() =>
              openDialog('Qual a cidade?', <Cities data={state?.id} onSelect={(c) => setCity(c)} />)
            }
          />
          <Input editable={false} variant="light" label="Cidade" value={city?.title} />
        </WrapperInput>
        <WrapperInput>
          <InputField
            onPress={() =>
              openDialog(
                'Qual o bairro?',
                <Neighborhoods cityId={city?.id} onSelect={(n) => setNeighborhood(n)} single />,
              )
            }
          />
          <Input editable={false} variant="light" label="Bairro" value={neighborhood?.title} />
        </WrapperInput>
      </WrapperForm>
      <WrapperButton>
        <Button
          disabled={!state || !city || !neighborhood || loading}
          variant="primary"
          title={loading ? 'Aguarde...' : 'Selecionar'}
          onPress={() => searchAddress()}
        />
      </WrapperButton>
    </Wrapper>
  );
};
