import React, { useState } from 'react';
import { Dimensions, Platform, View } from 'react-native';
import WebView from 'react-native-webview';
import { useTheme } from 'styled-components/native';
import { Loader } from '~/components/@hello-ui';

interface NamuIntegrationProps {
  uri: string | null;
}

export const NamuIntegration = ({ uri }: NamuIntegrationProps) => {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);

  if (Platform.OS === 'web') {
    window.addEventListener('message', (event) => {
      if (event.origin === 'https://webapp-api.namu.com.br') {
        onMessage && onMessage(event?.data);
      }
    });
  }

  const className = theme.isDesktop ? 'h-full min-h-[590px]' : '';
  return (
    <View className={className}>
      {!uri || loading ? (
        <Loader variant="circle-loader" />
      ) : Platform.OS === 'web' ? (
        <iframe
          src={uri}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-modals"
          style={{ width: '100%', height: '88vh', border: 'none' }}
        />
      ) : (
        <WebView
          source={{ uri: uri }}
          style={{ minHeight: Dimensions.get('screen').height - 230 }}
          scalesPageToFit={true}
          automaticallyAdjustContentInsets={false}
          nestedScrollEnabled
          allowsInlineMediaPlayback
          mediaPlaybackRequiresUserAction={false}
          startInLoadingState
          javaScriptEnabled
          domStorageEnabled
        />
      )}
    </View>
  );
};
