import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Linking, View } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import { Button, Typography } from '~/components/@hello-ui';

type TimeRestrictionProps = {
  grancePeriodDays: number;
};

export const ScheduleTimeRestriction = ({ grancePeriodDays }: TimeRestrictionProps) => {
  const theme = useTheme();
  const navigation = useNavigation();

  return (
    <View className="flex-1 justify-between">
      <View className="w-full gap-y-24">
        <Typography variant="title">Você ainda possui carência</Typography>

        <Typography variant="body1">
          Você ainda possui{' '}
          <Typography variant="bodyHighlight1">{grancePeriodDays} dias de carência</Typography>,
          após esse período você pode solicitar seu Check-Up Médico de acordo com o seu regulamento.
        </Typography>

        <Button
          variant="link"
          className="justify-start p-0"
          textStyle={{ marginLeft: -10 }}
          icon={<Icon name="chevron-right" size={24} color={theme.colors.primary} />}
          onPress={() => Linking.openURL('https://mkt.dd.meutem.com.br/wp-content/uploads/2021/02/RegulamentoTEM-Registrado.pdf')}
          iconPosition="right">
          Regulamento
        </Button>
      </View>

      <Button
        variant="primary"
        icon={<Icon name="chevron-left" size={24} color={theme.colors.white} />}
        onPress={() => navigation.navigate('BenefitsCheckup')}>
        Voltar
      </Button>
    </View>
  );
};