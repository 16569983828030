import { useShallow } from 'zustand/react/shallow';
import { useAuthStore } from '~/store/auth.store';
import { validateUserFeatures } from '~/utils/validateUserFeatures';

// TODO use Zustand stores instead
export function useAuth() {
  return useAuthStore(
    useShallow((st) => ({
      user: st.user,
      clinic: null,
      token: { token: st.token, refreshToken: st.refreshToken },
      signature: null,
      onixCode: st.selectedProduct
        ? {
            ...st.selectedProduct,
            onix_code: st.selectedProduct.onixCode,
          }
        : null,
      dependents: st.dependents,
      partner: st.selectedProduct
        ? {
            ...st.selectedProduct,
            onix_code: st.selectedProduct.onixCode,
            theme_config: {
              partner_full_name: st.selectedProduct.themeConfig.partnerFullName,
              partner_name: st.selectedProduct.themeConfig.partnerName,
              logo: st.selectedProduct.themeConfig.logo,
              colors: {
                primary: st.selectedProduct.themeConfig.colors.primary,
                secondary: st.selectedProduct.themeConfig.colors.secondary,
                contrast_text: st.selectedProduct.themeConfig.colors.contrastText,
                primary_alt: st.selectedProduct.themeConfig.colors.primaryAlt,
              },
              footer_phones: st.selectedProduct.themeConfig.footerPhones,
              social_links: st.selectedProduct.themeConfig.socialLinks,
              footer_disclaimers: st.selectedProduct.themeConfig.footerDisclaimers,
              copyright_text: st.selectedProduct.themeConfig.copyrightText,
              card_design_url: st.selectedProduct.themeConfig.cardDesignUrl,
              tem_pay_logo_url: st.selectedProduct.themeConfig.temPayLogoUrl,
              tem_pay_header_logo_url: st.selectedProduct.themeConfig.temPayHeaderLogoUrl,
              pay_name: st.selectedProduct.themeConfig.payName,
              dependents_limit: st.selectedProduct.themeConfig.dependentsLimit,
              dependents_max_age: st.selectedProduct.themeConfig.dependentsMaxAge,
            },
            created_at: null,
            updated_at: null,
            slug: st.selectedProduct.slug,
            features: st.selectedProduct.features,
            medicine_discount_provider: st.selectedProduct.medicineDiscountProvider,
            funeral_provider: st.selectedProduct.funeralProvider,
            dependent_features: st.selectedProduct.dependentFeatures,
          }
        : null,
      routePartner: null,
      loading: false,
      loadingPartner: false,
      loadingDependets: false,
      addressFirstAccess: false,
      isDependent: st.user?.isDependent ?? null,
      checkIsEnabledFeature: (features: string | string[], config?: 'some' | 'every') => {
        const userFeatures = st.user?.isDependent
          ? st.selectedProduct?.dependentFeatures
          : st.selectedProduct?.features;

        return userFeatures
          ? validateUserFeatures({ features, userFeatures, config: config ?? 'some' })
          : false;
      },
    })),
  );
}
