import React from 'react';
 
import { Linking, Platform, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Feather from 'react-native-vector-icons/dist/Feather';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../assets/images/BenefitsDentistry24Hours/desktop.png';
import mobileBackground from '../../assets/images/BenefitsDentistry24Hours/mobile.png';
import { CallIcon } from '~/components/Icons/Call';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { Button, Collapse, MobileAutoSpace, Typography, useMedia } from '~/components/@hello-ui';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { isItauOnix } from '~/helpers/itau';
import { useAuth } from '~/auth/legacy/useAuth';
import { Dentistry24HoursIcon } from '~/components/Icons/Dentistry24Hours';
 
export const BenefitsDentistry24Hours = (): JSX.Element => {
  const theme = useTheme();
  const media = useMedia();
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();
 
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;
 
  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <Dentistry24HoursIcon width={24} height={24} color='background-white'/>,
        title: 'Assistência de Urgência Odontológica',
        onBack: () => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            navigation.navigate('Benefits24Hours');
          }
        },
      }}>
     <View>
        <Typography variant="body2" className="mb-8">
            Atendimento 24 horas para <Typography variant="bodyHighlight2">cuidados odontológicos urgentes</Typography> sem necessidade de agendamento prévio.
        </Typography>
        <Typography variant="body2" className="mb-8">
            Você pode usar o serviço <Typography variant="bodyHighlight2">sempre que precisar</Typography> e <Typography variant="bodyHighlight2">sem carência</Typography>, como nas seguintes situações:
        </Typography>
        <View className="flex gap-8 p-8">
            <Typography variant="body2">• Dores agudas;</Typography>
            <Typography variant="body2">• Traumas;</Typography>
            <Typography variant="body2">• Sangramentos intensos;</Typography>
            <Typography variant="body2">• Outras condições.</Typography>
        </View>
        <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="bodyHighlight2" className="mb-8">
            Serviços disponíveis 24 horas
          </Typography>
          <View className="flex gap-8 p-8">
            <Typography variant="body2">• Consulta odontológica de urgência;</Typography>
            <Typography variant="body2">• Consulta odontológica de urgência 24h;</Typography>
            <Typography variant="body2">• Controle de hemorragia com a aplicação de agente hemostático em região buco-maxilo- facial;</Typography>
            <Typography variant="body2">• Controle de hemorragia sem a aplicação de agente hemostático em região buco-maxilo- facial;</Typography>
            <Typography variant="body2">• Incisão e drenagem extra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial;</Typography>
            <Typography variant="body2">• Incisão e drenagem intra-oral de abscesso, hematoma e/ou flegmão da região buco-maxilo-facial;</Typography>
            <Typography variant="body2">• Redução cruenta de fratura alvéolo dentária;</Typography>
            <Typography variant="body2">• Redução incruenta de fratura alvéolo dentária mandibular;</Typography>
            <Typography variant="body2">• Redução simples de luxação de articulação têmporo mandibular (ATM);</Typography>
            <Typography variant="body2">• Reimplante dentário com contenção;</Typography>
            <Typography variant="body2">• Sutura de ferida em região buco-maxilo-facial, Tratamento de alveolite;</Typography>
            <Typography variant="body2">• Tratamento de abscesso periodontal agudo;</Typography>
            <Typography variant="body2">• Colagem de fragmentos dentários, Pulpectomia;</Typography>
            <Typography variant="body2">• Restauração temporária / tratamento expectante;</Typography>
            <Typography variant="body2">• Tratamento pericoronarite;</Typography>
            <Typography variant="body2">• Recimentação de trabalhos protéticos.</Typography>
          </View>
          <Typography variant="bodyHighlight2" className="mb-8">
            Serviços disponíveis em horário comercial
          </Typography>
          <View className="flex gap-8 p-8">
            <Typography variant="body2">• Profilaxia – prolimento coronário orienta higiene bucal controle placa, aplicação de flúor;</Typography>
            <Typography variant="body2">• Aplicação tópica de flúor;</Typography>
            <Typography variant="body2">• Raspagem supra gengival.</Typography>
          </View>
        </Collapse>
        <Collapse title="Como usar?">
            <Typography variant="body2">Entre em contato com a Central de Atendimento indicada abaixo e solicite um atendimento.</Typography>
            <Typography variant="body2">Siga as instruções fornecidas pela equipe, que te direcionará para a clínica mais próxima que esteja aberta no momento.</Typography>
        </Collapse>
      </View>
      </View>
      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício, ligue para</Typography>
        <Typography variant="bodyHighlight2">a Central de Atendimento:</Typography>
      </View>
      {media.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:01140201675')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="body1" color={theme.colors.paragraph}>
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              (11) 4020-1675
            </Typography>
          </Typography>
        </View>
      )}
    </PageWithCard>
  );
};